import { getOldMulticaller } from '@/dependencies/OldMulticaller';
import ChainlinkOracleAbi from '@/lib/abi/ChainlinkOracle.json';
import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';
import Service from '../oracle-contracts.service';

export default class ChainlinkOracle {
  service: Service;

  constructor(service: Service) {
    this.service = service;
  }

  public async getLatestPrices(oracleAddresses: string[]) {
    const Multicaller = getOldMulticaller();
    const multiCaller = new Multicaller(
      this.service.config.key,
      this.service.provider,
      ChainlinkOracleAbi
    );

    oracleAddresses.map(address => {
      multiCaller.call(`latestAnswer.${address}`, address, 'latestAnswer');
    });

    const result: { latestAnswer: Record<string, BigNumber> } =
      await multiCaller.execute();

    const prices = oracleAddresses.map(address => {
      return formatUnits(result.latestAnswer[address], 8);
    });

    return prices;
  }
}
