import QUERY_KEYS from '@/constants/queryKeys';
import { TokenOracle } from '@/lib/config/types';
import { oracleContractsService } from '@/services/oracle/oracle-contracts.service';
import { getAddress } from '@ethersproject/address';
import { useQuery } from '@tanstack/vue-query';
import useNetwork from '../useNetwork';
import { oneMinInMs } from '../useTime';
import { TokenPrices } from './useTokenPricesQuery';

type QueryResponse = TokenPrices;

export default function useFXTokenPricesQuery(tokenOracles: TokenOracle[]) {
  // Query key
  const { networkId } = useNetwork();
  const oracles = tokenOracles.map(t => t.oracle);
  const queryKey = reactive(QUERY_KEYS.Tokens.OraclePrices(networkId, oracles));

  // Main query function
  const queryFn = async () => {
    const oraclePrices = await oracleContractsService.oracle.getLatestPrices(
      oracles
    );
    const tokens = tokenOracles.map(t => t.token);
    const prices = tokens.reduce(
      (obj, token, i) => (
        (obj[getAddress(token)] = parseFloat(oraclePrices[i])), obj
      ),
      {} as TokenPrices
    );
    return prices;
  };

  // Query options
  const queryOptions = reactive({
    enabled: true,
    refetchInterval: oneMinInMs * 5, // invalidates cache every 5 mins
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  return useQuery<QueryResponse>(queryKey, queryFn, queryOptions);
}
