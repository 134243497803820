import QUERY_KEYS from '@/constants/queryKeys';
import { getApi } from '@/dependencies/burrbear-api';
import { TokenInformation } from '@/services/burrbear-api/graphql/generated/api-types';
import { getAddress } from '@ethersproject/address';
import { useQuery, UseQueryOptions } from '@tanstack/vue-query';
import { reactive, Ref, ref } from 'vue';
import useNetwork from '../useNetwork';
import { oneMinInMs } from '../useTime';

/**
 * TYPES
 */
export type TokenPrices = { [address: string]: number };
type QueryResponse = TokenPrices;
type QueryOptions = UseQueryOptions<QueryResponse>;

// bartio addresses (part of a temp fix for incorrect iBGT price)
const LBGTAddress = '0x32Cf940DB5d7ea3e95e799A805B1471341241264';
const iBGTAddress = '0x46eFC86F0D7455F135CC9df501673739d513E982';

/**
 * Fetches token prices for all provided addresses.
 */
export default function useTokenPricesQuery(
  pricesToInject: Ref<TokenPrices> = ref({}),
  options: QueryOptions = {}
) {
  const { networkId } = useNetwork();
  const queryKey = reactive(
    QUERY_KEYS.Tokens.Prices(networkId, pricesToInject)
  );

  function tokenInfoArrayToMap(
    tokenInformations: TokenInformation[]
  ): TokenPrices {
    const tokenPrices = tokenInformations.reduce(
      (obj, item) => ((obj[getAddress(item.address)] = item.usdValue), obj),
      {}
    );

    // bartio temp fix for incorrect iBGT price
    if (Object.keys(tokenPrices).includes(iBGTAddress)) {
      if (Object.keys(tokenPrices).includes(LBGTAddress)) {
        // iBGT shoud have the same price as LBGT
        tokenPrices[iBGTAddress] = tokenPrices[LBGTAddress];
      } else {
        tokenPrices[iBGTAddress] = 0;
      }
    }

    return tokenPrices;
  }

  function injectCustomTokens(
    prices: TokenPrices,
    pricesToInject: TokenPrices
  ): TokenPrices {
    for (const address of Object.keys(pricesToInject)) {
      prices[address] = pricesToInject[address];
    }
    return prices;
  }

  const api = getApi();
  const queryFn = async () => {
    const { tokenInformations } = await api.GetTokenInformations();

    let pricesMap = tokenInfoArrayToMap(
      tokenInformations as TokenInformation[]
    );
    pricesMap = injectCustomTokens(pricesMap, pricesToInject.value);
    console.log('Fetching', tokenInformations.length, 'prices');

    return pricesMap;
  };

  const queryOptions = reactive({
    enabled: true,
    refetchInterval: oneMinInMs * 5,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    ...options,
  });

  return useQuery<QueryResponse>(
    queryKey,
    queryFn,
    queryOptions as QueryOptions
  );
}
