import { Config } from '@/lib/config/types';
import { configService as _configService } from '@/services/config/config.service';
import { rpcProviderService as _rpcProviderService } from '@/services/rpc-provider/rpc-provider.service';
import { JsonRpcProvider } from '@ethersproject/providers';
import ChainlinkOracle from './contracts/chainlink-oracle';

export default class OracleContractsService {
  oracle: ChainlinkOracle;
  config: Config;
  provider: JsonRpcProvider;

  constructor(
    readonly configService = _configService,
    readonly rpcProviderService = _rpcProviderService
  ) {
    this.provider = this.rpcProviderService.jsonProvider;
    this.config = this.configService.network;

    // Init contracts
    this.oracle = new ChainlinkOracle(this);
  }
}

export const oracleContractsService = new OracleContractsService();
