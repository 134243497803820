import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: string;
  BigInt: string;
  Bytes: string;
  Int8: any;
  Timestamp: any;
};

export type ActiveIncentive = {
  __typename?: 'ActiveIncentive';
  amountLeft: Scalars['BigDecimal'];
  id: Scalars['ID'];
  incentiveRate?: Maybe<Scalars['BigDecimal']>;
  token: TokenInformation;
  vault: Vault;
};

export type ActiveIncentive_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  amountLeft?: InputMaybe<Scalars['BigDecimal']>;
  amountLeft_gt?: InputMaybe<Scalars['BigDecimal']>;
  amountLeft_gte?: InputMaybe<Scalars['BigDecimal']>;
  amountLeft_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  amountLeft_lt?: InputMaybe<Scalars['BigDecimal']>;
  amountLeft_lte?: InputMaybe<Scalars['BigDecimal']>;
  amountLeft_not?: InputMaybe<Scalars['BigDecimal']>;
  amountLeft_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  and?: InputMaybe<Array<InputMaybe<ActiveIncentive_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  incentiveRate?: InputMaybe<Scalars['BigDecimal']>;
  incentiveRate_gt?: InputMaybe<Scalars['BigDecimal']>;
  incentiveRate_gte?: InputMaybe<Scalars['BigDecimal']>;
  incentiveRate_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  incentiveRate_lt?: InputMaybe<Scalars['BigDecimal']>;
  incentiveRate_lte?: InputMaybe<Scalars['BigDecimal']>;
  incentiveRate_not?: InputMaybe<Scalars['BigDecimal']>;
  incentiveRate_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<ActiveIncentive_Filter>>>;
  token?: InputMaybe<Scalars['String']>;
  token_?: InputMaybe<TokenInformation_Filter>;
  token_contains?: InputMaybe<Scalars['String']>;
  token_contains_nocase?: InputMaybe<Scalars['String']>;
  token_ends_with?: InputMaybe<Scalars['String']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_gt?: InputMaybe<Scalars['String']>;
  token_gte?: InputMaybe<Scalars['String']>;
  token_in?: InputMaybe<Array<Scalars['String']>>;
  token_lt?: InputMaybe<Scalars['String']>;
  token_lte?: InputMaybe<Scalars['String']>;
  token_not?: InputMaybe<Scalars['String']>;
  token_not_contains?: InputMaybe<Scalars['String']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_not_ends_with?: InputMaybe<Scalars['String']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_starts_with?: InputMaybe<Scalars['String']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']>;
  vault?: InputMaybe<Scalars['String']>;
  vault_?: InputMaybe<Vault_Filter>;
  vault_contains?: InputMaybe<Scalars['String']>;
  vault_contains_nocase?: InputMaybe<Scalars['String']>;
  vault_ends_with?: InputMaybe<Scalars['String']>;
  vault_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vault_gt?: InputMaybe<Scalars['String']>;
  vault_gte?: InputMaybe<Scalars['String']>;
  vault_in?: InputMaybe<Array<Scalars['String']>>;
  vault_lt?: InputMaybe<Scalars['String']>;
  vault_lte?: InputMaybe<Scalars['String']>;
  vault_not?: InputMaybe<Scalars['String']>;
  vault_not_contains?: InputMaybe<Scalars['String']>;
  vault_not_contains_nocase?: InputMaybe<Scalars['String']>;
  vault_not_ends_with?: InputMaybe<Scalars['String']>;
  vault_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vault_not_in?: InputMaybe<Array<Scalars['String']>>;
  vault_not_starts_with?: InputMaybe<Scalars['String']>;
  vault_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  vault_starts_with?: InputMaybe<Scalars['String']>;
  vault_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum ActiveIncentive_OrderBy {
  AmountLeft = 'amountLeft',
  Id = 'id',
  IncentiveRate = 'incentiveRate',
  Token = 'token',
  TokenAddress = 'token__address',
  TokenBeraValue = 'token__beraValue',
  TokenDecimals = 'token__decimals',
  TokenId = 'token__id',
  TokenName = 'token__name',
  TokenSymbol = 'token__symbol',
  TokenUsdValue = 'token__usdValue',
  Vault = 'vault',
  VaultId = 'vault__id',
  VaultStakingTokenAmount = 'vault__stakingTokenAmount',
  VaultVaultAddress = 'vault__vaultAddress',
}

export enum Aggregation_Interval {
  Day = 'day',
  Hour = 'hour',
}

export type AllTimeValidatorIncentivesData = {
  __typename?: 'AllTimeValidatorIncentivesData';
  allTimeBgtDirected: Scalars['BigDecimal'];
  allTimeBlocksProposed: Scalars['BigDecimal'];
  allTimeTokenRewards: Array<AllTimeValidatorTokenReward>;
  allTimeUsdValueBgtDirected: Scalars['BigDecimal'];
  allTimeUsdValueTokenRewards: Scalars['BigDecimal'];
  id: Scalars['ID'];
  validator: Validator;
};

export type AllTimeValidatorIncentivesDataAllTimeTokenRewardsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AllTimeValidatorTokenReward_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AllTimeValidatorTokenReward_Filter>;
};

export type AllTimeValidatorIncentivesData_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  allTimeBgtDirected?: InputMaybe<Scalars['BigDecimal']>;
  allTimeBgtDirected_gt?: InputMaybe<Scalars['BigDecimal']>;
  allTimeBgtDirected_gte?: InputMaybe<Scalars['BigDecimal']>;
  allTimeBgtDirected_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  allTimeBgtDirected_lt?: InputMaybe<Scalars['BigDecimal']>;
  allTimeBgtDirected_lte?: InputMaybe<Scalars['BigDecimal']>;
  allTimeBgtDirected_not?: InputMaybe<Scalars['BigDecimal']>;
  allTimeBgtDirected_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  allTimeBlocksProposed?: InputMaybe<Scalars['BigDecimal']>;
  allTimeBlocksProposed_gt?: InputMaybe<Scalars['BigDecimal']>;
  allTimeBlocksProposed_gte?: InputMaybe<Scalars['BigDecimal']>;
  allTimeBlocksProposed_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  allTimeBlocksProposed_lt?: InputMaybe<Scalars['BigDecimal']>;
  allTimeBlocksProposed_lte?: InputMaybe<Scalars['BigDecimal']>;
  allTimeBlocksProposed_not?: InputMaybe<Scalars['BigDecimal']>;
  allTimeBlocksProposed_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  allTimeTokenRewards_?: InputMaybe<AllTimeValidatorTokenReward_Filter>;
  allTimeUsdValueBgtDirected?: InputMaybe<Scalars['BigDecimal']>;
  allTimeUsdValueBgtDirected_gt?: InputMaybe<Scalars['BigDecimal']>;
  allTimeUsdValueBgtDirected_gte?: InputMaybe<Scalars['BigDecimal']>;
  allTimeUsdValueBgtDirected_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  allTimeUsdValueBgtDirected_lt?: InputMaybe<Scalars['BigDecimal']>;
  allTimeUsdValueBgtDirected_lte?: InputMaybe<Scalars['BigDecimal']>;
  allTimeUsdValueBgtDirected_not?: InputMaybe<Scalars['BigDecimal']>;
  allTimeUsdValueBgtDirected_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  allTimeUsdValueTokenRewards?: InputMaybe<Scalars['BigDecimal']>;
  allTimeUsdValueTokenRewards_gt?: InputMaybe<Scalars['BigDecimal']>;
  allTimeUsdValueTokenRewards_gte?: InputMaybe<Scalars['BigDecimal']>;
  allTimeUsdValueTokenRewards_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  allTimeUsdValueTokenRewards_lt?: InputMaybe<Scalars['BigDecimal']>;
  allTimeUsdValueTokenRewards_lte?: InputMaybe<Scalars['BigDecimal']>;
  allTimeUsdValueTokenRewards_not?: InputMaybe<Scalars['BigDecimal']>;
  allTimeUsdValueTokenRewards_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  and?: InputMaybe<Array<InputMaybe<AllTimeValidatorIncentivesData_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<AllTimeValidatorIncentivesData_Filter>>>;
  validator?: InputMaybe<Scalars['String']>;
  validator_?: InputMaybe<Validator_Filter>;
  validator_contains?: InputMaybe<Scalars['String']>;
  validator_contains_nocase?: InputMaybe<Scalars['String']>;
  validator_ends_with?: InputMaybe<Scalars['String']>;
  validator_ends_with_nocase?: InputMaybe<Scalars['String']>;
  validator_gt?: InputMaybe<Scalars['String']>;
  validator_gte?: InputMaybe<Scalars['String']>;
  validator_in?: InputMaybe<Array<Scalars['String']>>;
  validator_lt?: InputMaybe<Scalars['String']>;
  validator_lte?: InputMaybe<Scalars['String']>;
  validator_not?: InputMaybe<Scalars['String']>;
  validator_not_contains?: InputMaybe<Scalars['String']>;
  validator_not_contains_nocase?: InputMaybe<Scalars['String']>;
  validator_not_ends_with?: InputMaybe<Scalars['String']>;
  validator_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  validator_not_in?: InputMaybe<Array<Scalars['String']>>;
  validator_not_starts_with?: InputMaybe<Scalars['String']>;
  validator_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  validator_starts_with?: InputMaybe<Scalars['String']>;
  validator_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum AllTimeValidatorIncentivesData_OrderBy {
  AllTimeBgtDirected = 'allTimeBgtDirected',
  AllTimeBlocksProposed = 'allTimeBlocksProposed',
  AllTimeTokenRewards = 'allTimeTokenRewards',
  AllTimeUsdValueBgtDirected = 'allTimeUsdValueBgtDirected',
  AllTimeUsdValueTokenRewards = 'allTimeUsdValueTokenRewards',
  Id = 'id',
  Validator = 'validator',
  ValidatorAmountQueued = 'validator__amountQueued',
  ValidatorAmountStaked = 'validator__amountStaked',
  ValidatorCoinbase = 'validator__coinbase',
  ValidatorCommission = 'validator__commission',
  ValidatorId = 'validator__id',
}

export type AllTimeValidatorTokenReward = {
  __typename?: 'AllTimeValidatorTokenReward';
  allTimeValidatorIncentivesData: AllTimeValidatorIncentivesData;
  amount: Scalars['BigDecimal'];
  coinbase: Scalars['Bytes'];
  id: Scalars['ID'];
  token: TokenInformation;
  usdValue: Scalars['BigDecimal'];
};

export type AllTimeValidatorTokenReward_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  allTimeValidatorIncentivesData?: InputMaybe<Scalars['String']>;
  allTimeValidatorIncentivesData_?: InputMaybe<AllTimeValidatorIncentivesData_Filter>;
  allTimeValidatorIncentivesData_contains?: InputMaybe<Scalars['String']>;
  allTimeValidatorIncentivesData_contains_nocase?: InputMaybe<
    Scalars['String']
  >;
  allTimeValidatorIncentivesData_ends_with?: InputMaybe<Scalars['String']>;
  allTimeValidatorIncentivesData_ends_with_nocase?: InputMaybe<
    Scalars['String']
  >;
  allTimeValidatorIncentivesData_gt?: InputMaybe<Scalars['String']>;
  allTimeValidatorIncentivesData_gte?: InputMaybe<Scalars['String']>;
  allTimeValidatorIncentivesData_in?: InputMaybe<Array<Scalars['String']>>;
  allTimeValidatorIncentivesData_lt?: InputMaybe<Scalars['String']>;
  allTimeValidatorIncentivesData_lte?: InputMaybe<Scalars['String']>;
  allTimeValidatorIncentivesData_not?: InputMaybe<Scalars['String']>;
  allTimeValidatorIncentivesData_not_contains?: InputMaybe<Scalars['String']>;
  allTimeValidatorIncentivesData_not_contains_nocase?: InputMaybe<
    Scalars['String']
  >;
  allTimeValidatorIncentivesData_not_ends_with?: InputMaybe<Scalars['String']>;
  allTimeValidatorIncentivesData_not_ends_with_nocase?: InputMaybe<
    Scalars['String']
  >;
  allTimeValidatorIncentivesData_not_in?: InputMaybe<Array<Scalars['String']>>;
  allTimeValidatorIncentivesData_not_starts_with?: InputMaybe<
    Scalars['String']
  >;
  allTimeValidatorIncentivesData_not_starts_with_nocase?: InputMaybe<
    Scalars['String']
  >;
  allTimeValidatorIncentivesData_starts_with?: InputMaybe<Scalars['String']>;
  allTimeValidatorIncentivesData_starts_with_nocase?: InputMaybe<
    Scalars['String']
  >;
  amount?: InputMaybe<Scalars['BigDecimal']>;
  amount_gt?: InputMaybe<Scalars['BigDecimal']>;
  amount_gte?: InputMaybe<Scalars['BigDecimal']>;
  amount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  amount_lt?: InputMaybe<Scalars['BigDecimal']>;
  amount_lte?: InputMaybe<Scalars['BigDecimal']>;
  amount_not?: InputMaybe<Scalars['BigDecimal']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  and?: InputMaybe<Array<InputMaybe<AllTimeValidatorTokenReward_Filter>>>;
  coinbase?: InputMaybe<Scalars['Bytes']>;
  coinbase_contains?: InputMaybe<Scalars['Bytes']>;
  coinbase_gt?: InputMaybe<Scalars['Bytes']>;
  coinbase_gte?: InputMaybe<Scalars['Bytes']>;
  coinbase_in?: InputMaybe<Array<Scalars['Bytes']>>;
  coinbase_lt?: InputMaybe<Scalars['Bytes']>;
  coinbase_lte?: InputMaybe<Scalars['Bytes']>;
  coinbase_not?: InputMaybe<Scalars['Bytes']>;
  coinbase_not_contains?: InputMaybe<Scalars['Bytes']>;
  coinbase_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<AllTimeValidatorTokenReward_Filter>>>;
  token?: InputMaybe<Scalars['String']>;
  token_?: InputMaybe<TokenInformation_Filter>;
  token_contains?: InputMaybe<Scalars['String']>;
  token_contains_nocase?: InputMaybe<Scalars['String']>;
  token_ends_with?: InputMaybe<Scalars['String']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_gt?: InputMaybe<Scalars['String']>;
  token_gte?: InputMaybe<Scalars['String']>;
  token_in?: InputMaybe<Array<Scalars['String']>>;
  token_lt?: InputMaybe<Scalars['String']>;
  token_lte?: InputMaybe<Scalars['String']>;
  token_not?: InputMaybe<Scalars['String']>;
  token_not_contains?: InputMaybe<Scalars['String']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_not_ends_with?: InputMaybe<Scalars['String']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_starts_with?: InputMaybe<Scalars['String']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']>;
  usdValue?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_gt?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_gte?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  usdValue_lt?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_lte?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_not?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum AllTimeValidatorTokenReward_OrderBy {
  AllTimeValidatorIncentivesData = 'allTimeValidatorIncentivesData',
  AllTimeValidatorIncentivesDataAllTimeBgtDirected = 'allTimeValidatorIncentivesData__allTimeBgtDirected',
  AllTimeValidatorIncentivesDataAllTimeBlocksProposed = 'allTimeValidatorIncentivesData__allTimeBlocksProposed',
  AllTimeValidatorIncentivesDataAllTimeUsdValueBgtDirected = 'allTimeValidatorIncentivesData__allTimeUsdValueBgtDirected',
  AllTimeValidatorIncentivesDataAllTimeUsdValueTokenRewards = 'allTimeValidatorIncentivesData__allTimeUsdValueTokenRewards',
  AllTimeValidatorIncentivesDataId = 'allTimeValidatorIncentivesData__id',
  Amount = 'amount',
  Coinbase = 'coinbase',
  Id = 'id',
  Token = 'token',
  TokenAddress = 'token__address',
  TokenBeraValue = 'token__beraValue',
  TokenDecimals = 'token__decimals',
  TokenId = 'token__id',
  TokenName = 'token__name',
  TokenSymbol = 'token__symbol',
  TokenUsdValue = 'token__usdValue',
  UsdValue = 'usdValue',
}

export type AllTimeVaultData = {
  __typename?: 'AllTimeVaultData';
  allTimeBgtReceived: Scalars['BigDecimal'];
  allTimeUsdValueBgtReceived: Scalars['BigDecimal'];
  allTimeUsdValueTokensEmitted: Scalars['BigDecimal'];
  allTimebribesEmitted: Array<AllTimeVaultTokenEmissions>;
  id: Scalars['ID'];
  vault: Vault;
  vaultAddress: Scalars['Bytes'];
};

export type AllTimeVaultDataAllTimebribesEmittedArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AllTimeVaultTokenEmissions_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AllTimeVaultTokenEmissions_Filter>;
};

export type AllTimeVaultData_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  allTimeBgtReceived?: InputMaybe<Scalars['BigDecimal']>;
  allTimeBgtReceived_gt?: InputMaybe<Scalars['BigDecimal']>;
  allTimeBgtReceived_gte?: InputMaybe<Scalars['BigDecimal']>;
  allTimeBgtReceived_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  allTimeBgtReceived_lt?: InputMaybe<Scalars['BigDecimal']>;
  allTimeBgtReceived_lte?: InputMaybe<Scalars['BigDecimal']>;
  allTimeBgtReceived_not?: InputMaybe<Scalars['BigDecimal']>;
  allTimeBgtReceived_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  allTimeUsdValueBgtReceived?: InputMaybe<Scalars['BigDecimal']>;
  allTimeUsdValueBgtReceived_gt?: InputMaybe<Scalars['BigDecimal']>;
  allTimeUsdValueBgtReceived_gte?: InputMaybe<Scalars['BigDecimal']>;
  allTimeUsdValueBgtReceived_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  allTimeUsdValueBgtReceived_lt?: InputMaybe<Scalars['BigDecimal']>;
  allTimeUsdValueBgtReceived_lte?: InputMaybe<Scalars['BigDecimal']>;
  allTimeUsdValueBgtReceived_not?: InputMaybe<Scalars['BigDecimal']>;
  allTimeUsdValueBgtReceived_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  allTimeUsdValueTokensEmitted?: InputMaybe<Scalars['BigDecimal']>;
  allTimeUsdValueTokensEmitted_gt?: InputMaybe<Scalars['BigDecimal']>;
  allTimeUsdValueTokensEmitted_gte?: InputMaybe<Scalars['BigDecimal']>;
  allTimeUsdValueTokensEmitted_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  allTimeUsdValueTokensEmitted_lt?: InputMaybe<Scalars['BigDecimal']>;
  allTimeUsdValueTokensEmitted_lte?: InputMaybe<Scalars['BigDecimal']>;
  allTimeUsdValueTokensEmitted_not?: InputMaybe<Scalars['BigDecimal']>;
  allTimeUsdValueTokensEmitted_not_in?: InputMaybe<
    Array<Scalars['BigDecimal']>
  >;
  allTimebribesEmitted_?: InputMaybe<AllTimeVaultTokenEmissions_Filter>;
  and?: InputMaybe<Array<InputMaybe<AllTimeVaultData_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<AllTimeVaultData_Filter>>>;
  vault?: InputMaybe<Scalars['String']>;
  vaultAddress?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_contains?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_gt?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_gte?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_in?: InputMaybe<Array<Scalars['Bytes']>>;
  vaultAddress_lt?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_lte?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_not?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_not_contains?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  vault_?: InputMaybe<Vault_Filter>;
  vault_contains?: InputMaybe<Scalars['String']>;
  vault_contains_nocase?: InputMaybe<Scalars['String']>;
  vault_ends_with?: InputMaybe<Scalars['String']>;
  vault_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vault_gt?: InputMaybe<Scalars['String']>;
  vault_gte?: InputMaybe<Scalars['String']>;
  vault_in?: InputMaybe<Array<Scalars['String']>>;
  vault_lt?: InputMaybe<Scalars['String']>;
  vault_lte?: InputMaybe<Scalars['String']>;
  vault_not?: InputMaybe<Scalars['String']>;
  vault_not_contains?: InputMaybe<Scalars['String']>;
  vault_not_contains_nocase?: InputMaybe<Scalars['String']>;
  vault_not_ends_with?: InputMaybe<Scalars['String']>;
  vault_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vault_not_in?: InputMaybe<Array<Scalars['String']>>;
  vault_not_starts_with?: InputMaybe<Scalars['String']>;
  vault_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  vault_starts_with?: InputMaybe<Scalars['String']>;
  vault_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum AllTimeVaultData_OrderBy {
  AllTimeBgtReceived = 'allTimeBgtReceived',
  AllTimeUsdValueBgtReceived = 'allTimeUsdValueBgtReceived',
  AllTimeUsdValueTokensEmitted = 'allTimeUsdValueTokensEmitted',
  AllTimebribesEmitted = 'allTimebribesEmitted',
  Id = 'id',
  Vault = 'vault',
  VaultAddress = 'vaultAddress',
  VaultId = 'vault__id',
  VaultStakingTokenAmount = 'vault__stakingTokenAmount',
  VaultVaultAddress = 'vault__vaultAddress',
}

export type AllTimeVaultTokenEmissions = {
  __typename?: 'AllTimeVaultTokenEmissions';
  allTimeVaultData: AllTimeVaultData;
  amount: Scalars['BigDecimal'];
  id: Scalars['ID'];
  token: TokenInformation;
  usdValue: Scalars['BigDecimal'];
  vaultAddress: Scalars['Bytes'];
};

export type AllTimeVaultTokenEmissions_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  allTimeVaultData?: InputMaybe<Scalars['String']>;
  allTimeVaultData_?: InputMaybe<AllTimeVaultData_Filter>;
  allTimeVaultData_contains?: InputMaybe<Scalars['String']>;
  allTimeVaultData_contains_nocase?: InputMaybe<Scalars['String']>;
  allTimeVaultData_ends_with?: InputMaybe<Scalars['String']>;
  allTimeVaultData_ends_with_nocase?: InputMaybe<Scalars['String']>;
  allTimeVaultData_gt?: InputMaybe<Scalars['String']>;
  allTimeVaultData_gte?: InputMaybe<Scalars['String']>;
  allTimeVaultData_in?: InputMaybe<Array<Scalars['String']>>;
  allTimeVaultData_lt?: InputMaybe<Scalars['String']>;
  allTimeVaultData_lte?: InputMaybe<Scalars['String']>;
  allTimeVaultData_not?: InputMaybe<Scalars['String']>;
  allTimeVaultData_not_contains?: InputMaybe<Scalars['String']>;
  allTimeVaultData_not_contains_nocase?: InputMaybe<Scalars['String']>;
  allTimeVaultData_not_ends_with?: InputMaybe<Scalars['String']>;
  allTimeVaultData_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  allTimeVaultData_not_in?: InputMaybe<Array<Scalars['String']>>;
  allTimeVaultData_not_starts_with?: InputMaybe<Scalars['String']>;
  allTimeVaultData_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  allTimeVaultData_starts_with?: InputMaybe<Scalars['String']>;
  allTimeVaultData_starts_with_nocase?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['BigDecimal']>;
  amount_gt?: InputMaybe<Scalars['BigDecimal']>;
  amount_gte?: InputMaybe<Scalars['BigDecimal']>;
  amount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  amount_lt?: InputMaybe<Scalars['BigDecimal']>;
  amount_lte?: InputMaybe<Scalars['BigDecimal']>;
  amount_not?: InputMaybe<Scalars['BigDecimal']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  and?: InputMaybe<Array<InputMaybe<AllTimeVaultTokenEmissions_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<AllTimeVaultTokenEmissions_Filter>>>;
  token?: InputMaybe<Scalars['String']>;
  token_?: InputMaybe<TokenInformation_Filter>;
  token_contains?: InputMaybe<Scalars['String']>;
  token_contains_nocase?: InputMaybe<Scalars['String']>;
  token_ends_with?: InputMaybe<Scalars['String']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_gt?: InputMaybe<Scalars['String']>;
  token_gte?: InputMaybe<Scalars['String']>;
  token_in?: InputMaybe<Array<Scalars['String']>>;
  token_lt?: InputMaybe<Scalars['String']>;
  token_lte?: InputMaybe<Scalars['String']>;
  token_not?: InputMaybe<Scalars['String']>;
  token_not_contains?: InputMaybe<Scalars['String']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_not_ends_with?: InputMaybe<Scalars['String']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_starts_with?: InputMaybe<Scalars['String']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']>;
  usdValue?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_gt?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_gte?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  usdValue_lt?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_lte?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_not?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  vaultAddress?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_contains?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_gt?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_gte?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_in?: InputMaybe<Array<Scalars['Bytes']>>;
  vaultAddress_lt?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_lte?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_not?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_not_contains?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
};

export enum AllTimeVaultTokenEmissions_OrderBy {
  AllTimeVaultData = 'allTimeVaultData',
  AllTimeVaultDataAllTimeBgtReceived = 'allTimeVaultData__allTimeBgtReceived',
  AllTimeVaultDataAllTimeUsdValueBgtReceived = 'allTimeVaultData__allTimeUsdValueBgtReceived',
  AllTimeVaultDataAllTimeUsdValueTokensEmitted = 'allTimeVaultData__allTimeUsdValueTokensEmitted',
  AllTimeVaultDataId = 'allTimeVaultData__id',
  AllTimeVaultDataVaultAddress = 'allTimeVaultData__vaultAddress',
  Amount = 'amount',
  Id = 'id',
  Token = 'token',
  TokenAddress = 'token__address',
  TokenBeraValue = 'token__beraValue',
  TokenDecimals = 'token__decimals',
  TokenId = 'token__id',
  TokenName = 'token__name',
  TokenSymbol = 'token__symbol',
  TokenUsdValue = 'token__usdValue',
  UsdValue = 'usdValue',
  VaultAddress = 'vaultAddress',
}

export type BexGlobalData = {
  __typename?: 'BexGlobalData';
  id: Scalars['ID'];
  poolCount: Scalars['BigDecimal'];
  tvlUsd: Scalars['BigDecimal'];
  txCount: Scalars['BigDecimal'];
  volumeUsd: Scalars['BigDecimal'];
};

export type BexGlobalData_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<BexGlobalData_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<BexGlobalData_Filter>>>;
  poolCount?: InputMaybe<Scalars['BigDecimal']>;
  poolCount_gt?: InputMaybe<Scalars['BigDecimal']>;
  poolCount_gte?: InputMaybe<Scalars['BigDecimal']>;
  poolCount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  poolCount_lt?: InputMaybe<Scalars['BigDecimal']>;
  poolCount_lte?: InputMaybe<Scalars['BigDecimal']>;
  poolCount_not?: InputMaybe<Scalars['BigDecimal']>;
  poolCount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  tvlUsd?: InputMaybe<Scalars['BigDecimal']>;
  tvlUsd_gt?: InputMaybe<Scalars['BigDecimal']>;
  tvlUsd_gte?: InputMaybe<Scalars['BigDecimal']>;
  tvlUsd_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  tvlUsd_lt?: InputMaybe<Scalars['BigDecimal']>;
  tvlUsd_lte?: InputMaybe<Scalars['BigDecimal']>;
  tvlUsd_not?: InputMaybe<Scalars['BigDecimal']>;
  tvlUsd_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  txCount?: InputMaybe<Scalars['BigDecimal']>;
  txCount_gt?: InputMaybe<Scalars['BigDecimal']>;
  txCount_gte?: InputMaybe<Scalars['BigDecimal']>;
  txCount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  txCount_lt?: InputMaybe<Scalars['BigDecimal']>;
  txCount_lte?: InputMaybe<Scalars['BigDecimal']>;
  txCount_not?: InputMaybe<Scalars['BigDecimal']>;
  txCount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volumeUsd?: InputMaybe<Scalars['BigDecimal']>;
  volumeUsd_gt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUsd_gte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUsd_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volumeUsd_lt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUsd_lte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUsd_not?: InputMaybe<Scalars['BigDecimal']>;
  volumeUsd_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum BexGlobalData_OrderBy {
  Id = 'id',
  PoolCount = 'poolCount',
  TvlUsd = 'tvlUsd',
  TxCount = 'txCount',
  VolumeUsd = 'volumeUsd',
}

export type BexGlobalDayData = {
  __typename?: 'BexGlobalDayData';
  date: Scalars['Int'];
  id: Scalars['ID'];
  tvlUsd: Scalars['BigDecimal'];
  txCount: Scalars['BigDecimal'];
  volumeUsd: Scalars['BigDecimal'];
};

export type BexGlobalDayData_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<BexGlobalDayData_Filter>>>;
  date?: InputMaybe<Scalars['Int']>;
  date_gt?: InputMaybe<Scalars['Int']>;
  date_gte?: InputMaybe<Scalars['Int']>;
  date_in?: InputMaybe<Array<Scalars['Int']>>;
  date_lt?: InputMaybe<Scalars['Int']>;
  date_lte?: InputMaybe<Scalars['Int']>;
  date_not?: InputMaybe<Scalars['Int']>;
  date_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<BexGlobalDayData_Filter>>>;
  tvlUsd?: InputMaybe<Scalars['BigDecimal']>;
  tvlUsd_gt?: InputMaybe<Scalars['BigDecimal']>;
  tvlUsd_gte?: InputMaybe<Scalars['BigDecimal']>;
  tvlUsd_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  tvlUsd_lt?: InputMaybe<Scalars['BigDecimal']>;
  tvlUsd_lte?: InputMaybe<Scalars['BigDecimal']>;
  tvlUsd_not?: InputMaybe<Scalars['BigDecimal']>;
  tvlUsd_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  txCount?: InputMaybe<Scalars['BigDecimal']>;
  txCount_gt?: InputMaybe<Scalars['BigDecimal']>;
  txCount_gte?: InputMaybe<Scalars['BigDecimal']>;
  txCount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  txCount_lt?: InputMaybe<Scalars['BigDecimal']>;
  txCount_lte?: InputMaybe<Scalars['BigDecimal']>;
  txCount_not?: InputMaybe<Scalars['BigDecimal']>;
  txCount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volumeUsd?: InputMaybe<Scalars['BigDecimal']>;
  volumeUsd_gt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUsd_gte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUsd_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volumeUsd_lt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUsd_lte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUsd_not?: InputMaybe<Scalars['BigDecimal']>;
  volumeUsd_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum BexGlobalDayData_OrderBy {
  Date = 'date',
  Id = 'id',
  TvlUsd = 'tvlUsd',
  TxCount = 'txCount',
  VolumeUsd = 'volumeUsd',
}

export type BlockChangedFilter = {
  number_gte: Scalars['Int'];
};

export type Block_Height = {
  hash?: InputMaybe<Scalars['Bytes']>;
  number?: InputMaybe<Scalars['Int']>;
  number_gte?: InputMaybe<Scalars['Int']>;
};

export type DailyValidatorIncentivesData = {
  __typename?: 'DailyValidatorIncentivesData';
  dailyBgtDirected: Scalars['BigDecimal'];
  dailyTokenRewards: Array<DailyValidatorTokenReward>;
  dailyUsdValueBgtDirected: Scalars['BigDecimal'];
  dailyUsdValueTokenRewards: Scalars['BigDecimal'];
  date: Scalars['Int'];
  id: Scalars['ID'];
  validator: Validator;
};

export type DailyValidatorIncentivesDataDailyTokenRewardsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DailyValidatorTokenReward_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DailyValidatorTokenReward_Filter>;
};

export type DailyValidatorIncentivesData_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<DailyValidatorIncentivesData_Filter>>>;
  dailyBgtDirected?: InputMaybe<Scalars['BigDecimal']>;
  dailyBgtDirected_gt?: InputMaybe<Scalars['BigDecimal']>;
  dailyBgtDirected_gte?: InputMaybe<Scalars['BigDecimal']>;
  dailyBgtDirected_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  dailyBgtDirected_lt?: InputMaybe<Scalars['BigDecimal']>;
  dailyBgtDirected_lte?: InputMaybe<Scalars['BigDecimal']>;
  dailyBgtDirected_not?: InputMaybe<Scalars['BigDecimal']>;
  dailyBgtDirected_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  dailyTokenRewards_?: InputMaybe<DailyValidatorTokenReward_Filter>;
  dailyUsdValueBgtDirected?: InputMaybe<Scalars['BigDecimal']>;
  dailyUsdValueBgtDirected_gt?: InputMaybe<Scalars['BigDecimal']>;
  dailyUsdValueBgtDirected_gte?: InputMaybe<Scalars['BigDecimal']>;
  dailyUsdValueBgtDirected_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  dailyUsdValueBgtDirected_lt?: InputMaybe<Scalars['BigDecimal']>;
  dailyUsdValueBgtDirected_lte?: InputMaybe<Scalars['BigDecimal']>;
  dailyUsdValueBgtDirected_not?: InputMaybe<Scalars['BigDecimal']>;
  dailyUsdValueBgtDirected_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  dailyUsdValueTokenRewards?: InputMaybe<Scalars['BigDecimal']>;
  dailyUsdValueTokenRewards_gt?: InputMaybe<Scalars['BigDecimal']>;
  dailyUsdValueTokenRewards_gte?: InputMaybe<Scalars['BigDecimal']>;
  dailyUsdValueTokenRewards_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  dailyUsdValueTokenRewards_lt?: InputMaybe<Scalars['BigDecimal']>;
  dailyUsdValueTokenRewards_lte?: InputMaybe<Scalars['BigDecimal']>;
  dailyUsdValueTokenRewards_not?: InputMaybe<Scalars['BigDecimal']>;
  dailyUsdValueTokenRewards_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  date?: InputMaybe<Scalars['Int']>;
  date_gt?: InputMaybe<Scalars['Int']>;
  date_gte?: InputMaybe<Scalars['Int']>;
  date_in?: InputMaybe<Array<Scalars['Int']>>;
  date_lt?: InputMaybe<Scalars['Int']>;
  date_lte?: InputMaybe<Scalars['Int']>;
  date_not?: InputMaybe<Scalars['Int']>;
  date_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<DailyValidatorIncentivesData_Filter>>>;
  validator?: InputMaybe<Scalars['String']>;
  validator_?: InputMaybe<Validator_Filter>;
  validator_contains?: InputMaybe<Scalars['String']>;
  validator_contains_nocase?: InputMaybe<Scalars['String']>;
  validator_ends_with?: InputMaybe<Scalars['String']>;
  validator_ends_with_nocase?: InputMaybe<Scalars['String']>;
  validator_gt?: InputMaybe<Scalars['String']>;
  validator_gte?: InputMaybe<Scalars['String']>;
  validator_in?: InputMaybe<Array<Scalars['String']>>;
  validator_lt?: InputMaybe<Scalars['String']>;
  validator_lte?: InputMaybe<Scalars['String']>;
  validator_not?: InputMaybe<Scalars['String']>;
  validator_not_contains?: InputMaybe<Scalars['String']>;
  validator_not_contains_nocase?: InputMaybe<Scalars['String']>;
  validator_not_ends_with?: InputMaybe<Scalars['String']>;
  validator_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  validator_not_in?: InputMaybe<Array<Scalars['String']>>;
  validator_not_starts_with?: InputMaybe<Scalars['String']>;
  validator_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  validator_starts_with?: InputMaybe<Scalars['String']>;
  validator_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum DailyValidatorIncentivesData_OrderBy {
  DailyBgtDirected = 'dailyBgtDirected',
  DailyTokenRewards = 'dailyTokenRewards',
  DailyUsdValueBgtDirected = 'dailyUsdValueBgtDirected',
  DailyUsdValueTokenRewards = 'dailyUsdValueTokenRewards',
  Date = 'date',
  Id = 'id',
  Validator = 'validator',
  ValidatorAmountQueued = 'validator__amountQueued',
  ValidatorAmountStaked = 'validator__amountStaked',
  ValidatorCoinbase = 'validator__coinbase',
  ValidatorCommission = 'validator__commission',
  ValidatorId = 'validator__id',
}

export type DailyValidatorTokenReward = {
  __typename?: 'DailyValidatorTokenReward';
  amount: Scalars['BigDecimal'];
  coinbase: Scalars['Bytes'];
  dailyValidatorIncentivesData: DailyValidatorIncentivesData;
  id: Scalars['ID'];
  token: TokenInformation;
  usdValue: Scalars['BigDecimal'];
};

export type DailyValidatorTokenReward_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  amount?: InputMaybe<Scalars['BigDecimal']>;
  amount_gt?: InputMaybe<Scalars['BigDecimal']>;
  amount_gte?: InputMaybe<Scalars['BigDecimal']>;
  amount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  amount_lt?: InputMaybe<Scalars['BigDecimal']>;
  amount_lte?: InputMaybe<Scalars['BigDecimal']>;
  amount_not?: InputMaybe<Scalars['BigDecimal']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  and?: InputMaybe<Array<InputMaybe<DailyValidatorTokenReward_Filter>>>;
  coinbase?: InputMaybe<Scalars['Bytes']>;
  coinbase_contains?: InputMaybe<Scalars['Bytes']>;
  coinbase_gt?: InputMaybe<Scalars['Bytes']>;
  coinbase_gte?: InputMaybe<Scalars['Bytes']>;
  coinbase_in?: InputMaybe<Array<Scalars['Bytes']>>;
  coinbase_lt?: InputMaybe<Scalars['Bytes']>;
  coinbase_lte?: InputMaybe<Scalars['Bytes']>;
  coinbase_not?: InputMaybe<Scalars['Bytes']>;
  coinbase_not_contains?: InputMaybe<Scalars['Bytes']>;
  coinbase_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  dailyValidatorIncentivesData?: InputMaybe<Scalars['String']>;
  dailyValidatorIncentivesData_?: InputMaybe<DailyValidatorIncentivesData_Filter>;
  dailyValidatorIncentivesData_contains?: InputMaybe<Scalars['String']>;
  dailyValidatorIncentivesData_contains_nocase?: InputMaybe<Scalars['String']>;
  dailyValidatorIncentivesData_ends_with?: InputMaybe<Scalars['String']>;
  dailyValidatorIncentivesData_ends_with_nocase?: InputMaybe<Scalars['String']>;
  dailyValidatorIncentivesData_gt?: InputMaybe<Scalars['String']>;
  dailyValidatorIncentivesData_gte?: InputMaybe<Scalars['String']>;
  dailyValidatorIncentivesData_in?: InputMaybe<Array<Scalars['String']>>;
  dailyValidatorIncentivesData_lt?: InputMaybe<Scalars['String']>;
  dailyValidatorIncentivesData_lte?: InputMaybe<Scalars['String']>;
  dailyValidatorIncentivesData_not?: InputMaybe<Scalars['String']>;
  dailyValidatorIncentivesData_not_contains?: InputMaybe<Scalars['String']>;
  dailyValidatorIncentivesData_not_contains_nocase?: InputMaybe<
    Scalars['String']
  >;
  dailyValidatorIncentivesData_not_ends_with?: InputMaybe<Scalars['String']>;
  dailyValidatorIncentivesData_not_ends_with_nocase?: InputMaybe<
    Scalars['String']
  >;
  dailyValidatorIncentivesData_not_in?: InputMaybe<Array<Scalars['String']>>;
  dailyValidatorIncentivesData_not_starts_with?: InputMaybe<Scalars['String']>;
  dailyValidatorIncentivesData_not_starts_with_nocase?: InputMaybe<
    Scalars['String']
  >;
  dailyValidatorIncentivesData_starts_with?: InputMaybe<Scalars['String']>;
  dailyValidatorIncentivesData_starts_with_nocase?: InputMaybe<
    Scalars['String']
  >;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<DailyValidatorTokenReward_Filter>>>;
  token?: InputMaybe<Scalars['String']>;
  token_?: InputMaybe<TokenInformation_Filter>;
  token_contains?: InputMaybe<Scalars['String']>;
  token_contains_nocase?: InputMaybe<Scalars['String']>;
  token_ends_with?: InputMaybe<Scalars['String']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_gt?: InputMaybe<Scalars['String']>;
  token_gte?: InputMaybe<Scalars['String']>;
  token_in?: InputMaybe<Array<Scalars['String']>>;
  token_lt?: InputMaybe<Scalars['String']>;
  token_lte?: InputMaybe<Scalars['String']>;
  token_not?: InputMaybe<Scalars['String']>;
  token_not_contains?: InputMaybe<Scalars['String']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_not_ends_with?: InputMaybe<Scalars['String']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_starts_with?: InputMaybe<Scalars['String']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']>;
  usdValue?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_gt?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_gte?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  usdValue_lt?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_lte?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_not?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum DailyValidatorTokenReward_OrderBy {
  Amount = 'amount',
  Coinbase = 'coinbase',
  DailyValidatorIncentivesData = 'dailyValidatorIncentivesData',
  DailyValidatorIncentivesDataDailyBgtDirected = 'dailyValidatorIncentivesData__dailyBgtDirected',
  DailyValidatorIncentivesDataDailyUsdValueBgtDirected = 'dailyValidatorIncentivesData__dailyUsdValueBgtDirected',
  DailyValidatorIncentivesDataDailyUsdValueTokenRewards = 'dailyValidatorIncentivesData__dailyUsdValueTokenRewards',
  DailyValidatorIncentivesDataDate = 'dailyValidatorIncentivesData__date',
  DailyValidatorIncentivesDataId = 'dailyValidatorIncentivesData__id',
  Id = 'id',
  Token = 'token',
  TokenAddress = 'token__address',
  TokenBeraValue = 'token__beraValue',
  TokenDecimals = 'token__decimals',
  TokenId = 'token__id',
  TokenName = 'token__name',
  TokenSymbol = 'token__symbol',
  TokenUsdValue = 'token__usdValue',
  UsdValue = 'usdValue',
}

export type DailyVaultData = {
  __typename?: 'DailyVaultData';
  dailyBgtReceived: Scalars['BigDecimal'];
  dailyBribesEmitted: Array<DailyVaultTokenEmissions>;
  dailyUsdValueBgtReceived: Scalars['BigDecimal'];
  dailyUsdValueTokensEmitted: Scalars['BigDecimal'];
  date: Scalars['Int'];
  id: Scalars['ID'];
  vault: Vault;
  vaultAddress: Scalars['Bytes'];
};

export type DailyVaultDataDailyBribesEmittedArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DailyVaultTokenEmissions_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DailyVaultTokenEmissions_Filter>;
};

export type DailyVaultData_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<DailyVaultData_Filter>>>;
  dailyBgtReceived?: InputMaybe<Scalars['BigDecimal']>;
  dailyBgtReceived_gt?: InputMaybe<Scalars['BigDecimal']>;
  dailyBgtReceived_gte?: InputMaybe<Scalars['BigDecimal']>;
  dailyBgtReceived_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  dailyBgtReceived_lt?: InputMaybe<Scalars['BigDecimal']>;
  dailyBgtReceived_lte?: InputMaybe<Scalars['BigDecimal']>;
  dailyBgtReceived_not?: InputMaybe<Scalars['BigDecimal']>;
  dailyBgtReceived_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  dailyBribesEmitted_?: InputMaybe<DailyVaultTokenEmissions_Filter>;
  dailyUsdValueBgtReceived?: InputMaybe<Scalars['BigDecimal']>;
  dailyUsdValueBgtReceived_gt?: InputMaybe<Scalars['BigDecimal']>;
  dailyUsdValueBgtReceived_gte?: InputMaybe<Scalars['BigDecimal']>;
  dailyUsdValueBgtReceived_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  dailyUsdValueBgtReceived_lt?: InputMaybe<Scalars['BigDecimal']>;
  dailyUsdValueBgtReceived_lte?: InputMaybe<Scalars['BigDecimal']>;
  dailyUsdValueBgtReceived_not?: InputMaybe<Scalars['BigDecimal']>;
  dailyUsdValueBgtReceived_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  dailyUsdValueTokensEmitted?: InputMaybe<Scalars['BigDecimal']>;
  dailyUsdValueTokensEmitted_gt?: InputMaybe<Scalars['BigDecimal']>;
  dailyUsdValueTokensEmitted_gte?: InputMaybe<Scalars['BigDecimal']>;
  dailyUsdValueTokensEmitted_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  dailyUsdValueTokensEmitted_lt?: InputMaybe<Scalars['BigDecimal']>;
  dailyUsdValueTokensEmitted_lte?: InputMaybe<Scalars['BigDecimal']>;
  dailyUsdValueTokensEmitted_not?: InputMaybe<Scalars['BigDecimal']>;
  dailyUsdValueTokensEmitted_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  date?: InputMaybe<Scalars['Int']>;
  date_gt?: InputMaybe<Scalars['Int']>;
  date_gte?: InputMaybe<Scalars['Int']>;
  date_in?: InputMaybe<Array<Scalars['Int']>>;
  date_lt?: InputMaybe<Scalars['Int']>;
  date_lte?: InputMaybe<Scalars['Int']>;
  date_not?: InputMaybe<Scalars['Int']>;
  date_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<DailyVaultData_Filter>>>;
  vault?: InputMaybe<Scalars['String']>;
  vaultAddress?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_contains?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_gt?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_gte?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_in?: InputMaybe<Array<Scalars['Bytes']>>;
  vaultAddress_lt?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_lte?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_not?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_not_contains?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  vault_?: InputMaybe<Vault_Filter>;
  vault_contains?: InputMaybe<Scalars['String']>;
  vault_contains_nocase?: InputMaybe<Scalars['String']>;
  vault_ends_with?: InputMaybe<Scalars['String']>;
  vault_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vault_gt?: InputMaybe<Scalars['String']>;
  vault_gte?: InputMaybe<Scalars['String']>;
  vault_in?: InputMaybe<Array<Scalars['String']>>;
  vault_lt?: InputMaybe<Scalars['String']>;
  vault_lte?: InputMaybe<Scalars['String']>;
  vault_not?: InputMaybe<Scalars['String']>;
  vault_not_contains?: InputMaybe<Scalars['String']>;
  vault_not_contains_nocase?: InputMaybe<Scalars['String']>;
  vault_not_ends_with?: InputMaybe<Scalars['String']>;
  vault_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vault_not_in?: InputMaybe<Array<Scalars['String']>>;
  vault_not_starts_with?: InputMaybe<Scalars['String']>;
  vault_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  vault_starts_with?: InputMaybe<Scalars['String']>;
  vault_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum DailyVaultData_OrderBy {
  DailyBgtReceived = 'dailyBgtReceived',
  DailyBribesEmitted = 'dailyBribesEmitted',
  DailyUsdValueBgtReceived = 'dailyUsdValueBgtReceived',
  DailyUsdValueTokensEmitted = 'dailyUsdValueTokensEmitted',
  Date = 'date',
  Id = 'id',
  Vault = 'vault',
  VaultAddress = 'vaultAddress',
  VaultId = 'vault__id',
  VaultStakingTokenAmount = 'vault__stakingTokenAmount',
  VaultVaultAddress = 'vault__vaultAddress',
}

export type DailyVaultTokenEmissions = {
  __typename?: 'DailyVaultTokenEmissions';
  amount: Scalars['BigDecimal'];
  dailyVaultData: DailyVaultData;
  id: Scalars['ID'];
  token: TokenInformation;
  usdValue: Scalars['BigDecimal'];
};

export type DailyVaultTokenEmissions_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  amount?: InputMaybe<Scalars['BigDecimal']>;
  amount_gt?: InputMaybe<Scalars['BigDecimal']>;
  amount_gte?: InputMaybe<Scalars['BigDecimal']>;
  amount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  amount_lt?: InputMaybe<Scalars['BigDecimal']>;
  amount_lte?: InputMaybe<Scalars['BigDecimal']>;
  amount_not?: InputMaybe<Scalars['BigDecimal']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  and?: InputMaybe<Array<InputMaybe<DailyVaultTokenEmissions_Filter>>>;
  dailyVaultData?: InputMaybe<Scalars['String']>;
  dailyVaultData_?: InputMaybe<DailyVaultData_Filter>;
  dailyVaultData_contains?: InputMaybe<Scalars['String']>;
  dailyVaultData_contains_nocase?: InputMaybe<Scalars['String']>;
  dailyVaultData_ends_with?: InputMaybe<Scalars['String']>;
  dailyVaultData_ends_with_nocase?: InputMaybe<Scalars['String']>;
  dailyVaultData_gt?: InputMaybe<Scalars['String']>;
  dailyVaultData_gte?: InputMaybe<Scalars['String']>;
  dailyVaultData_in?: InputMaybe<Array<Scalars['String']>>;
  dailyVaultData_lt?: InputMaybe<Scalars['String']>;
  dailyVaultData_lte?: InputMaybe<Scalars['String']>;
  dailyVaultData_not?: InputMaybe<Scalars['String']>;
  dailyVaultData_not_contains?: InputMaybe<Scalars['String']>;
  dailyVaultData_not_contains_nocase?: InputMaybe<Scalars['String']>;
  dailyVaultData_not_ends_with?: InputMaybe<Scalars['String']>;
  dailyVaultData_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  dailyVaultData_not_in?: InputMaybe<Array<Scalars['String']>>;
  dailyVaultData_not_starts_with?: InputMaybe<Scalars['String']>;
  dailyVaultData_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  dailyVaultData_starts_with?: InputMaybe<Scalars['String']>;
  dailyVaultData_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<DailyVaultTokenEmissions_Filter>>>;
  token?: InputMaybe<Scalars['String']>;
  token_?: InputMaybe<TokenInformation_Filter>;
  token_contains?: InputMaybe<Scalars['String']>;
  token_contains_nocase?: InputMaybe<Scalars['String']>;
  token_ends_with?: InputMaybe<Scalars['String']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_gt?: InputMaybe<Scalars['String']>;
  token_gte?: InputMaybe<Scalars['String']>;
  token_in?: InputMaybe<Array<Scalars['String']>>;
  token_lt?: InputMaybe<Scalars['String']>;
  token_lte?: InputMaybe<Scalars['String']>;
  token_not?: InputMaybe<Scalars['String']>;
  token_not_contains?: InputMaybe<Scalars['String']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_not_ends_with?: InputMaybe<Scalars['String']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_starts_with?: InputMaybe<Scalars['String']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']>;
  usdValue?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_gt?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_gte?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  usdValue_lt?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_lte?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_not?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum DailyVaultTokenEmissions_OrderBy {
  Amount = 'amount',
  DailyVaultData = 'dailyVaultData',
  DailyVaultDataDailyBgtReceived = 'dailyVaultData__dailyBgtReceived',
  DailyVaultDataDailyUsdValueBgtReceived = 'dailyVaultData__dailyUsdValueBgtReceived',
  DailyVaultDataDailyUsdValueTokensEmitted = 'dailyVaultData__dailyUsdValueTokensEmitted',
  DailyVaultDataDate = 'dailyVaultData__date',
  DailyVaultDataId = 'dailyVaultData__id',
  DailyVaultDataVaultAddress = 'dailyVaultData__vaultAddress',
  Id = 'id',
  Token = 'token',
  TokenAddress = 'token__address',
  TokenBeraValue = 'token__beraValue',
  TokenDecimals = 'token__decimals',
  TokenId = 'token__id',
  TokenName = 'token__name',
  TokenSymbol = 'token__symbol',
  TokenUsdValue = 'token__usdValue',
  UsdValue = 'usdValue',
}

export type DefaultCuttingBoard = {
  __typename?: 'DefaultCuttingBoard';
  id: Scalars['ID'];
  startBlock: Scalars['BigInt'];
  weights: Array<DefaultCuttingBoardWeight>;
};

export type DefaultCuttingBoardWeightsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DefaultCuttingBoardWeight_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DefaultCuttingBoardWeight_Filter>;
};

export type DefaultCuttingBoardWeight = {
  __typename?: 'DefaultCuttingBoardWeight';
  id: Scalars['ID'];
  owner: DefaultCuttingBoard;
  percentageNumerator: Scalars['BigInt'];
  receiver: Scalars['Bytes'];
  vault?: Maybe<Vault>;
};

export type DefaultCuttingBoardWeight_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<DefaultCuttingBoardWeight_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<DefaultCuttingBoardWeight_Filter>>>;
  owner?: InputMaybe<Scalars['String']>;
  owner_?: InputMaybe<DefaultCuttingBoard_Filter>;
  owner_contains?: InputMaybe<Scalars['String']>;
  owner_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_ends_with?: InputMaybe<Scalars['String']>;
  owner_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_gt?: InputMaybe<Scalars['String']>;
  owner_gte?: InputMaybe<Scalars['String']>;
  owner_in?: InputMaybe<Array<Scalars['String']>>;
  owner_lt?: InputMaybe<Scalars['String']>;
  owner_lte?: InputMaybe<Scalars['String']>;
  owner_not?: InputMaybe<Scalars['String']>;
  owner_not_contains?: InputMaybe<Scalars['String']>;
  owner_not_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  owner_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_not_in?: InputMaybe<Array<Scalars['String']>>;
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  owner_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  owner_starts_with?: InputMaybe<Scalars['String']>;
  owner_starts_with_nocase?: InputMaybe<Scalars['String']>;
  percentageNumerator?: InputMaybe<Scalars['BigInt']>;
  percentageNumerator_gt?: InputMaybe<Scalars['BigInt']>;
  percentageNumerator_gte?: InputMaybe<Scalars['BigInt']>;
  percentageNumerator_in?: InputMaybe<Array<Scalars['BigInt']>>;
  percentageNumerator_lt?: InputMaybe<Scalars['BigInt']>;
  percentageNumerator_lte?: InputMaybe<Scalars['BigInt']>;
  percentageNumerator_not?: InputMaybe<Scalars['BigInt']>;
  percentageNumerator_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  receiver?: InputMaybe<Scalars['Bytes']>;
  receiver_contains?: InputMaybe<Scalars['Bytes']>;
  receiver_gt?: InputMaybe<Scalars['Bytes']>;
  receiver_gte?: InputMaybe<Scalars['Bytes']>;
  receiver_in?: InputMaybe<Array<Scalars['Bytes']>>;
  receiver_lt?: InputMaybe<Scalars['Bytes']>;
  receiver_lte?: InputMaybe<Scalars['Bytes']>;
  receiver_not?: InputMaybe<Scalars['Bytes']>;
  receiver_not_contains?: InputMaybe<Scalars['Bytes']>;
  receiver_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  vault?: InputMaybe<Scalars['String']>;
  vault_?: InputMaybe<Vault_Filter>;
  vault_contains?: InputMaybe<Scalars['String']>;
  vault_contains_nocase?: InputMaybe<Scalars['String']>;
  vault_ends_with?: InputMaybe<Scalars['String']>;
  vault_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vault_gt?: InputMaybe<Scalars['String']>;
  vault_gte?: InputMaybe<Scalars['String']>;
  vault_in?: InputMaybe<Array<Scalars['String']>>;
  vault_lt?: InputMaybe<Scalars['String']>;
  vault_lte?: InputMaybe<Scalars['String']>;
  vault_not?: InputMaybe<Scalars['String']>;
  vault_not_contains?: InputMaybe<Scalars['String']>;
  vault_not_contains_nocase?: InputMaybe<Scalars['String']>;
  vault_not_ends_with?: InputMaybe<Scalars['String']>;
  vault_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vault_not_in?: InputMaybe<Array<Scalars['String']>>;
  vault_not_starts_with?: InputMaybe<Scalars['String']>;
  vault_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  vault_starts_with?: InputMaybe<Scalars['String']>;
  vault_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum DefaultCuttingBoardWeight_OrderBy {
  Id = 'id',
  Owner = 'owner',
  OwnerId = 'owner__id',
  OwnerStartBlock = 'owner__startBlock',
  PercentageNumerator = 'percentageNumerator',
  Receiver = 'receiver',
  Vault = 'vault',
  VaultId = 'vault__id',
  VaultStakingTokenAmount = 'vault__stakingTokenAmount',
  VaultVaultAddress = 'vault__vaultAddress',
}

export type DefaultCuttingBoard_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<DefaultCuttingBoard_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<DefaultCuttingBoard_Filter>>>;
  startBlock?: InputMaybe<Scalars['BigInt']>;
  startBlock_gt?: InputMaybe<Scalars['BigInt']>;
  startBlock_gte?: InputMaybe<Scalars['BigInt']>;
  startBlock_in?: InputMaybe<Array<Scalars['BigInt']>>;
  startBlock_lt?: InputMaybe<Scalars['BigInt']>;
  startBlock_lte?: InputMaybe<Scalars['BigInt']>;
  startBlock_not?: InputMaybe<Scalars['BigInt']>;
  startBlock_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  weights_?: InputMaybe<DefaultCuttingBoardWeight_Filter>;
};

export enum DefaultCuttingBoard_OrderBy {
  Id = 'id',
  StartBlock = 'startBlock',
  Weights = 'weights',
}

/**
 * A FeeChange represents an update to the swap fee setting on a given CrocSwap
 * liquidity pool.
 *
 */
export type FeeChange = {
  __typename?: 'FeeChange';
  block: Scalars['BigInt'];
  feeRate: Scalars['Int'];
  id: Scalars['Bytes'];
  pool: Pool;
  time: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
};

export type FeeChange_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<FeeChange_Filter>>>;
  block?: InputMaybe<Scalars['BigInt']>;
  block_gt?: InputMaybe<Scalars['BigInt']>;
  block_gte?: InputMaybe<Scalars['BigInt']>;
  block_in?: InputMaybe<Array<Scalars['BigInt']>>;
  block_lt?: InputMaybe<Scalars['BigInt']>;
  block_lte?: InputMaybe<Scalars['BigInt']>;
  block_not?: InputMaybe<Scalars['BigInt']>;
  block_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  feeRate?: InputMaybe<Scalars['Int']>;
  feeRate_gt?: InputMaybe<Scalars['Int']>;
  feeRate_gte?: InputMaybe<Scalars['Int']>;
  feeRate_in?: InputMaybe<Array<Scalars['Int']>>;
  feeRate_lt?: InputMaybe<Scalars['Int']>;
  feeRate_lte?: InputMaybe<Scalars['Int']>;
  feeRate_not?: InputMaybe<Scalars['Int']>;
  feeRate_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['Bytes']>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  or?: InputMaybe<Array<InputMaybe<FeeChange_Filter>>>;
  pool?: InputMaybe<Scalars['String']>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars['String']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_ends_with?: InputMaybe<Scalars['String']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_gt?: InputMaybe<Scalars['String']>;
  pool_gte?: InputMaybe<Scalars['String']>;
  pool_in?: InputMaybe<Array<Scalars['String']>>;
  pool_lt?: InputMaybe<Scalars['String']>;
  pool_lte?: InputMaybe<Scalars['String']>;
  pool_not?: InputMaybe<Scalars['String']>;
  pool_not_contains?: InputMaybe<Scalars['String']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_not_in?: InputMaybe<Array<Scalars['String']>>;
  pool_not_starts_with?: InputMaybe<Scalars['String']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  pool_starts_with?: InputMaybe<Scalars['String']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']>;
  time?: InputMaybe<Scalars['BigInt']>;
  time_gt?: InputMaybe<Scalars['BigInt']>;
  time_gte?: InputMaybe<Scalars['BigInt']>;
  time_in?: InputMaybe<Array<Scalars['BigInt']>>;
  time_lt?: InputMaybe<Scalars['BigInt']>;
  time_lte?: InputMaybe<Scalars['BigInt']>;
  time_not?: InputMaybe<Scalars['BigInt']>;
  time_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
};

export enum FeeChange_OrderBy {
  Block = 'block',
  FeeRate = 'feeRate',
  Id = 'id',
  Pool = 'pool',
  PoolBase = 'pool__base',
  PoolBaseAmount = 'pool__baseAmount',
  PoolBgtApy = 'pool__bgtApy',
  PoolBlockCreate = 'pool__blockCreate',
  PoolId = 'pool__id',
  PoolPoolIdx = 'pool__poolIdx',
  PoolQuote = 'pool__quote',
  PoolQuoteAmount = 'pool__quoteAmount',
  PoolTimeCreate = 'pool__timeCreate',
  PoolTvlUsd = 'pool__tvlUsd',
  Time = 'time',
  TransactionHash = 'transactionHash',
}

export type FriendsOfTheChef = {
  __typename?: 'FriendsOfTheChef';
  id: Scalars['ID'];
  isFriend: Scalars['Boolean'];
  receiver: Scalars['Bytes'];
};

export type FriendsOfTheChef_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<FriendsOfTheChef_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  isFriend?: InputMaybe<Scalars['Boolean']>;
  isFriend_in?: InputMaybe<Array<Scalars['Boolean']>>;
  isFriend_not?: InputMaybe<Scalars['Boolean']>;
  isFriend_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  or?: InputMaybe<Array<InputMaybe<FriendsOfTheChef_Filter>>>;
  receiver?: InputMaybe<Scalars['Bytes']>;
  receiver_contains?: InputMaybe<Scalars['Bytes']>;
  receiver_gt?: InputMaybe<Scalars['Bytes']>;
  receiver_gte?: InputMaybe<Scalars['Bytes']>;
  receiver_in?: InputMaybe<Array<Scalars['Bytes']>>;
  receiver_lt?: InputMaybe<Scalars['Bytes']>;
  receiver_lte?: InputMaybe<Scalars['Bytes']>;
  receiver_not?: InputMaybe<Scalars['Bytes']>;
  receiver_not_contains?: InputMaybe<Scalars['Bytes']>;
  receiver_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
};

export enum FriendsOfTheChef_OrderBy {
  Id = 'id',
  IsFriend = 'isFriend',
  Receiver = 'receiver',
}

export type GlobalCuttingBoardWeight = {
  __typename?: 'GlobalCuttingBoardWeight';
  amount: Scalars['BigDecimal'];
  id: Scalars['ID'];
  receiver: Scalars['Bytes'];
  vault?: Maybe<Vault>;
};

export type GlobalCuttingBoardWeight_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  amount?: InputMaybe<Scalars['BigDecimal']>;
  amount_gt?: InputMaybe<Scalars['BigDecimal']>;
  amount_gte?: InputMaybe<Scalars['BigDecimal']>;
  amount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  amount_lt?: InputMaybe<Scalars['BigDecimal']>;
  amount_lte?: InputMaybe<Scalars['BigDecimal']>;
  amount_not?: InputMaybe<Scalars['BigDecimal']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  and?: InputMaybe<Array<InputMaybe<GlobalCuttingBoardWeight_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<GlobalCuttingBoardWeight_Filter>>>;
  receiver?: InputMaybe<Scalars['Bytes']>;
  receiver_contains?: InputMaybe<Scalars['Bytes']>;
  receiver_gt?: InputMaybe<Scalars['Bytes']>;
  receiver_gte?: InputMaybe<Scalars['Bytes']>;
  receiver_in?: InputMaybe<Array<Scalars['Bytes']>>;
  receiver_lt?: InputMaybe<Scalars['Bytes']>;
  receiver_lte?: InputMaybe<Scalars['Bytes']>;
  receiver_not?: InputMaybe<Scalars['Bytes']>;
  receiver_not_contains?: InputMaybe<Scalars['Bytes']>;
  receiver_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  vault?: InputMaybe<Scalars['String']>;
  vault_?: InputMaybe<Vault_Filter>;
  vault_contains?: InputMaybe<Scalars['String']>;
  vault_contains_nocase?: InputMaybe<Scalars['String']>;
  vault_ends_with?: InputMaybe<Scalars['String']>;
  vault_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vault_gt?: InputMaybe<Scalars['String']>;
  vault_gte?: InputMaybe<Scalars['String']>;
  vault_in?: InputMaybe<Array<Scalars['String']>>;
  vault_lt?: InputMaybe<Scalars['String']>;
  vault_lte?: InputMaybe<Scalars['String']>;
  vault_not?: InputMaybe<Scalars['String']>;
  vault_not_contains?: InputMaybe<Scalars['String']>;
  vault_not_contains_nocase?: InputMaybe<Scalars['String']>;
  vault_not_ends_with?: InputMaybe<Scalars['String']>;
  vault_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vault_not_in?: InputMaybe<Array<Scalars['String']>>;
  vault_not_starts_with?: InputMaybe<Scalars['String']>;
  vault_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  vault_starts_with?: InputMaybe<Scalars['String']>;
  vault_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum GlobalCuttingBoardWeight_OrderBy {
  Amount = 'amount',
  Id = 'id',
  Receiver = 'receiver',
  Vault = 'vault',
  VaultId = 'vault__id',
  VaultStakingTokenAmount = 'vault__stakingTokenAmount',
  VaultVaultAddress = 'vault__vaultAddress',
}

export type GlobalInfo = {
  __typename?: 'GlobalInfo';
  baseRewardRate: Scalars['BigDecimal'];
  id: Scalars['ID'];
  rewardRate: Scalars['BigDecimal'];
  totalBGTDistributed: Scalars['BigDecimal'];
  totalBgtQueued: Scalars['BigDecimal'];
  totalBgtStaked: Scalars['BigDecimal'];
  totalUsdValueBribesVolume: Scalars['BigDecimal'];
  totalValidators: Scalars['BigDecimal'];
};

export type GlobalInfo_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<GlobalInfo_Filter>>>;
  baseRewardRate?: InputMaybe<Scalars['BigDecimal']>;
  baseRewardRate_gt?: InputMaybe<Scalars['BigDecimal']>;
  baseRewardRate_gte?: InputMaybe<Scalars['BigDecimal']>;
  baseRewardRate_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  baseRewardRate_lt?: InputMaybe<Scalars['BigDecimal']>;
  baseRewardRate_lte?: InputMaybe<Scalars['BigDecimal']>;
  baseRewardRate_not?: InputMaybe<Scalars['BigDecimal']>;
  baseRewardRate_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<GlobalInfo_Filter>>>;
  rewardRate?: InputMaybe<Scalars['BigDecimal']>;
  rewardRate_gt?: InputMaybe<Scalars['BigDecimal']>;
  rewardRate_gte?: InputMaybe<Scalars['BigDecimal']>;
  rewardRate_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  rewardRate_lt?: InputMaybe<Scalars['BigDecimal']>;
  rewardRate_lte?: InputMaybe<Scalars['BigDecimal']>;
  rewardRate_not?: InputMaybe<Scalars['BigDecimal']>;
  rewardRate_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalBGTDistributed?: InputMaybe<Scalars['BigDecimal']>;
  totalBGTDistributed_gt?: InputMaybe<Scalars['BigDecimal']>;
  totalBGTDistributed_gte?: InputMaybe<Scalars['BigDecimal']>;
  totalBGTDistributed_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalBGTDistributed_lt?: InputMaybe<Scalars['BigDecimal']>;
  totalBGTDistributed_lte?: InputMaybe<Scalars['BigDecimal']>;
  totalBGTDistributed_not?: InputMaybe<Scalars['BigDecimal']>;
  totalBGTDistributed_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalBgtQueued?: InputMaybe<Scalars['BigDecimal']>;
  totalBgtQueued_gt?: InputMaybe<Scalars['BigDecimal']>;
  totalBgtQueued_gte?: InputMaybe<Scalars['BigDecimal']>;
  totalBgtQueued_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalBgtQueued_lt?: InputMaybe<Scalars['BigDecimal']>;
  totalBgtQueued_lte?: InputMaybe<Scalars['BigDecimal']>;
  totalBgtQueued_not?: InputMaybe<Scalars['BigDecimal']>;
  totalBgtQueued_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalBgtStaked?: InputMaybe<Scalars['BigDecimal']>;
  totalBgtStaked_gt?: InputMaybe<Scalars['BigDecimal']>;
  totalBgtStaked_gte?: InputMaybe<Scalars['BigDecimal']>;
  totalBgtStaked_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalBgtStaked_lt?: InputMaybe<Scalars['BigDecimal']>;
  totalBgtStaked_lte?: InputMaybe<Scalars['BigDecimal']>;
  totalBgtStaked_not?: InputMaybe<Scalars['BigDecimal']>;
  totalBgtStaked_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalUsdValueBribesVolume?: InputMaybe<Scalars['BigDecimal']>;
  totalUsdValueBribesVolume_gt?: InputMaybe<Scalars['BigDecimal']>;
  totalUsdValueBribesVolume_gte?: InputMaybe<Scalars['BigDecimal']>;
  totalUsdValueBribesVolume_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalUsdValueBribesVolume_lt?: InputMaybe<Scalars['BigDecimal']>;
  totalUsdValueBribesVolume_lte?: InputMaybe<Scalars['BigDecimal']>;
  totalUsdValueBribesVolume_not?: InputMaybe<Scalars['BigDecimal']>;
  totalUsdValueBribesVolume_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalValidators?: InputMaybe<Scalars['BigDecimal']>;
  totalValidators_gt?: InputMaybe<Scalars['BigDecimal']>;
  totalValidators_gte?: InputMaybe<Scalars['BigDecimal']>;
  totalValidators_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalValidators_lt?: InputMaybe<Scalars['BigDecimal']>;
  totalValidators_lte?: InputMaybe<Scalars['BigDecimal']>;
  totalValidators_not?: InputMaybe<Scalars['BigDecimal']>;
  totalValidators_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum GlobalInfo_OrderBy {
  BaseRewardRate = 'baseRewardRate',
  Id = 'id',
  RewardRate = 'rewardRate',
  TotalBgtDistributed = 'totalBGTDistributed',
  TotalBgtQueued = 'totalBgtQueued',
  TotalBgtStaked = 'totalBgtStaked',
  TotalUsdValueBribesVolume = 'totalUsdValueBribesVolume',
  TotalValidators = 'totalValidators',
}

/**
 * A LiquidityChange entity represents a single modification to a single
 * liquidity position made on CrocSwap. LiquidityChanges are categorized
 * according to their changeType, which can be equal to mint, burn, harvest,
 * claim, or recover.
 *
 */
export type LiquidityChange = {
  __typename?: 'LiquidityChange';
  askTick?: Maybe<Scalars['Int']>;
  baseAssetBeraPrice?: Maybe<Scalars['BigDecimal']>;
  baseAssetUsdPrice?: Maybe<Scalars['BigDecimal']>;
  baseFlow?: Maybe<Scalars['BigInt']>;
  bidTick?: Maybe<Scalars['Int']>;
  block: Scalars['BigInt'];
  callSource: Scalars['String'];
  changeType: Scalars['String'];
  id: Scalars['Bytes'];
  isBid: Scalars['Boolean'];
  liq?: Maybe<Scalars['BigInt']>;
  pivotTime?: Maybe<Scalars['BigInt']>;
  pool: Pool;
  positionType: Scalars['String'];
  quoteAssetBeraPrice?: Maybe<Scalars['BigDecimal']>;
  quoteAssetUsdPrice?: Maybe<Scalars['BigDecimal']>;
  quoteFlow?: Maybe<Scalars['BigInt']>;
  time: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
  user: Scalars['Bytes'];
};

export type LiquidityChange_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<LiquidityChange_Filter>>>;
  askTick?: InputMaybe<Scalars['Int']>;
  askTick_gt?: InputMaybe<Scalars['Int']>;
  askTick_gte?: InputMaybe<Scalars['Int']>;
  askTick_in?: InputMaybe<Array<Scalars['Int']>>;
  askTick_lt?: InputMaybe<Scalars['Int']>;
  askTick_lte?: InputMaybe<Scalars['Int']>;
  askTick_not?: InputMaybe<Scalars['Int']>;
  askTick_not_in?: InputMaybe<Array<Scalars['Int']>>;
  baseAssetBeraPrice?: InputMaybe<Scalars['BigDecimal']>;
  baseAssetBeraPrice_gt?: InputMaybe<Scalars['BigDecimal']>;
  baseAssetBeraPrice_gte?: InputMaybe<Scalars['BigDecimal']>;
  baseAssetBeraPrice_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  baseAssetBeraPrice_lt?: InputMaybe<Scalars['BigDecimal']>;
  baseAssetBeraPrice_lte?: InputMaybe<Scalars['BigDecimal']>;
  baseAssetBeraPrice_not?: InputMaybe<Scalars['BigDecimal']>;
  baseAssetBeraPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  baseAssetUsdPrice?: InputMaybe<Scalars['BigDecimal']>;
  baseAssetUsdPrice_gt?: InputMaybe<Scalars['BigDecimal']>;
  baseAssetUsdPrice_gte?: InputMaybe<Scalars['BigDecimal']>;
  baseAssetUsdPrice_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  baseAssetUsdPrice_lt?: InputMaybe<Scalars['BigDecimal']>;
  baseAssetUsdPrice_lte?: InputMaybe<Scalars['BigDecimal']>;
  baseAssetUsdPrice_not?: InputMaybe<Scalars['BigDecimal']>;
  baseAssetUsdPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  baseFlow?: InputMaybe<Scalars['BigInt']>;
  baseFlow_gt?: InputMaybe<Scalars['BigInt']>;
  baseFlow_gte?: InputMaybe<Scalars['BigInt']>;
  baseFlow_in?: InputMaybe<Array<Scalars['BigInt']>>;
  baseFlow_lt?: InputMaybe<Scalars['BigInt']>;
  baseFlow_lte?: InputMaybe<Scalars['BigInt']>;
  baseFlow_not?: InputMaybe<Scalars['BigInt']>;
  baseFlow_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  bidTick?: InputMaybe<Scalars['Int']>;
  bidTick_gt?: InputMaybe<Scalars['Int']>;
  bidTick_gte?: InputMaybe<Scalars['Int']>;
  bidTick_in?: InputMaybe<Array<Scalars['Int']>>;
  bidTick_lt?: InputMaybe<Scalars['Int']>;
  bidTick_lte?: InputMaybe<Scalars['Int']>;
  bidTick_not?: InputMaybe<Scalars['Int']>;
  bidTick_not_in?: InputMaybe<Array<Scalars['Int']>>;
  block?: InputMaybe<Scalars['BigInt']>;
  block_gt?: InputMaybe<Scalars['BigInt']>;
  block_gte?: InputMaybe<Scalars['BigInt']>;
  block_in?: InputMaybe<Array<Scalars['BigInt']>>;
  block_lt?: InputMaybe<Scalars['BigInt']>;
  block_lte?: InputMaybe<Scalars['BigInt']>;
  block_not?: InputMaybe<Scalars['BigInt']>;
  block_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  callSource?: InputMaybe<Scalars['String']>;
  callSource_contains?: InputMaybe<Scalars['String']>;
  callSource_contains_nocase?: InputMaybe<Scalars['String']>;
  callSource_ends_with?: InputMaybe<Scalars['String']>;
  callSource_ends_with_nocase?: InputMaybe<Scalars['String']>;
  callSource_gt?: InputMaybe<Scalars['String']>;
  callSource_gte?: InputMaybe<Scalars['String']>;
  callSource_in?: InputMaybe<Array<Scalars['String']>>;
  callSource_lt?: InputMaybe<Scalars['String']>;
  callSource_lte?: InputMaybe<Scalars['String']>;
  callSource_not?: InputMaybe<Scalars['String']>;
  callSource_not_contains?: InputMaybe<Scalars['String']>;
  callSource_not_contains_nocase?: InputMaybe<Scalars['String']>;
  callSource_not_ends_with?: InputMaybe<Scalars['String']>;
  callSource_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  callSource_not_in?: InputMaybe<Array<Scalars['String']>>;
  callSource_not_starts_with?: InputMaybe<Scalars['String']>;
  callSource_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  callSource_starts_with?: InputMaybe<Scalars['String']>;
  callSource_starts_with_nocase?: InputMaybe<Scalars['String']>;
  changeType?: InputMaybe<Scalars['String']>;
  changeType_contains?: InputMaybe<Scalars['String']>;
  changeType_contains_nocase?: InputMaybe<Scalars['String']>;
  changeType_ends_with?: InputMaybe<Scalars['String']>;
  changeType_ends_with_nocase?: InputMaybe<Scalars['String']>;
  changeType_gt?: InputMaybe<Scalars['String']>;
  changeType_gte?: InputMaybe<Scalars['String']>;
  changeType_in?: InputMaybe<Array<Scalars['String']>>;
  changeType_lt?: InputMaybe<Scalars['String']>;
  changeType_lte?: InputMaybe<Scalars['String']>;
  changeType_not?: InputMaybe<Scalars['String']>;
  changeType_not_contains?: InputMaybe<Scalars['String']>;
  changeType_not_contains_nocase?: InputMaybe<Scalars['String']>;
  changeType_not_ends_with?: InputMaybe<Scalars['String']>;
  changeType_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  changeType_not_in?: InputMaybe<Array<Scalars['String']>>;
  changeType_not_starts_with?: InputMaybe<Scalars['String']>;
  changeType_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  changeType_starts_with?: InputMaybe<Scalars['String']>;
  changeType_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Bytes']>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  isBid?: InputMaybe<Scalars['Boolean']>;
  isBid_in?: InputMaybe<Array<Scalars['Boolean']>>;
  isBid_not?: InputMaybe<Scalars['Boolean']>;
  isBid_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  liq?: InputMaybe<Scalars['BigInt']>;
  liq_gt?: InputMaybe<Scalars['BigInt']>;
  liq_gte?: InputMaybe<Scalars['BigInt']>;
  liq_in?: InputMaybe<Array<Scalars['BigInt']>>;
  liq_lt?: InputMaybe<Scalars['BigInt']>;
  liq_lte?: InputMaybe<Scalars['BigInt']>;
  liq_not?: InputMaybe<Scalars['BigInt']>;
  liq_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  or?: InputMaybe<Array<InputMaybe<LiquidityChange_Filter>>>;
  pivotTime?: InputMaybe<Scalars['BigInt']>;
  pivotTime_gt?: InputMaybe<Scalars['BigInt']>;
  pivotTime_gte?: InputMaybe<Scalars['BigInt']>;
  pivotTime_in?: InputMaybe<Array<Scalars['BigInt']>>;
  pivotTime_lt?: InputMaybe<Scalars['BigInt']>;
  pivotTime_lte?: InputMaybe<Scalars['BigInt']>;
  pivotTime_not?: InputMaybe<Scalars['BigInt']>;
  pivotTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  pool?: InputMaybe<Scalars['String']>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars['String']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_ends_with?: InputMaybe<Scalars['String']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_gt?: InputMaybe<Scalars['String']>;
  pool_gte?: InputMaybe<Scalars['String']>;
  pool_in?: InputMaybe<Array<Scalars['String']>>;
  pool_lt?: InputMaybe<Scalars['String']>;
  pool_lte?: InputMaybe<Scalars['String']>;
  pool_not?: InputMaybe<Scalars['String']>;
  pool_not_contains?: InputMaybe<Scalars['String']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_not_in?: InputMaybe<Array<Scalars['String']>>;
  pool_not_starts_with?: InputMaybe<Scalars['String']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  pool_starts_with?: InputMaybe<Scalars['String']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']>;
  positionType?: InputMaybe<Scalars['String']>;
  positionType_contains?: InputMaybe<Scalars['String']>;
  positionType_contains_nocase?: InputMaybe<Scalars['String']>;
  positionType_ends_with?: InputMaybe<Scalars['String']>;
  positionType_ends_with_nocase?: InputMaybe<Scalars['String']>;
  positionType_gt?: InputMaybe<Scalars['String']>;
  positionType_gte?: InputMaybe<Scalars['String']>;
  positionType_in?: InputMaybe<Array<Scalars['String']>>;
  positionType_lt?: InputMaybe<Scalars['String']>;
  positionType_lte?: InputMaybe<Scalars['String']>;
  positionType_not?: InputMaybe<Scalars['String']>;
  positionType_not_contains?: InputMaybe<Scalars['String']>;
  positionType_not_contains_nocase?: InputMaybe<Scalars['String']>;
  positionType_not_ends_with?: InputMaybe<Scalars['String']>;
  positionType_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  positionType_not_in?: InputMaybe<Array<Scalars['String']>>;
  positionType_not_starts_with?: InputMaybe<Scalars['String']>;
  positionType_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  positionType_starts_with?: InputMaybe<Scalars['String']>;
  positionType_starts_with_nocase?: InputMaybe<Scalars['String']>;
  quoteAssetBeraPrice?: InputMaybe<Scalars['BigDecimal']>;
  quoteAssetBeraPrice_gt?: InputMaybe<Scalars['BigDecimal']>;
  quoteAssetBeraPrice_gte?: InputMaybe<Scalars['BigDecimal']>;
  quoteAssetBeraPrice_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  quoteAssetBeraPrice_lt?: InputMaybe<Scalars['BigDecimal']>;
  quoteAssetBeraPrice_lte?: InputMaybe<Scalars['BigDecimal']>;
  quoteAssetBeraPrice_not?: InputMaybe<Scalars['BigDecimal']>;
  quoteAssetBeraPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  quoteAssetUsdPrice?: InputMaybe<Scalars['BigDecimal']>;
  quoteAssetUsdPrice_gt?: InputMaybe<Scalars['BigDecimal']>;
  quoteAssetUsdPrice_gte?: InputMaybe<Scalars['BigDecimal']>;
  quoteAssetUsdPrice_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  quoteAssetUsdPrice_lt?: InputMaybe<Scalars['BigDecimal']>;
  quoteAssetUsdPrice_lte?: InputMaybe<Scalars['BigDecimal']>;
  quoteAssetUsdPrice_not?: InputMaybe<Scalars['BigDecimal']>;
  quoteAssetUsdPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  quoteFlow?: InputMaybe<Scalars['BigInt']>;
  quoteFlow_gt?: InputMaybe<Scalars['BigInt']>;
  quoteFlow_gte?: InputMaybe<Scalars['BigInt']>;
  quoteFlow_in?: InputMaybe<Array<Scalars['BigInt']>>;
  quoteFlow_lt?: InputMaybe<Scalars['BigInt']>;
  quoteFlow_lte?: InputMaybe<Scalars['BigInt']>;
  quoteFlow_not?: InputMaybe<Scalars['BigInt']>;
  quoteFlow_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  time?: InputMaybe<Scalars['BigInt']>;
  time_gt?: InputMaybe<Scalars['BigInt']>;
  time_gte?: InputMaybe<Scalars['BigInt']>;
  time_in?: InputMaybe<Array<Scalars['BigInt']>>;
  time_lt?: InputMaybe<Scalars['BigInt']>;
  time_lte?: InputMaybe<Scalars['BigInt']>;
  time_not?: InputMaybe<Scalars['BigInt']>;
  time_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user?: InputMaybe<Scalars['Bytes']>;
  user_contains?: InputMaybe<Scalars['Bytes']>;
  user_gt?: InputMaybe<Scalars['Bytes']>;
  user_gte?: InputMaybe<Scalars['Bytes']>;
  user_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_lt?: InputMaybe<Scalars['Bytes']>;
  user_lte?: InputMaybe<Scalars['Bytes']>;
  user_not?: InputMaybe<Scalars['Bytes']>;
  user_not_contains?: InputMaybe<Scalars['Bytes']>;
  user_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
};

export enum LiquidityChange_OrderBy {
  AskTick = 'askTick',
  BaseAssetBeraPrice = 'baseAssetBeraPrice',
  BaseAssetUsdPrice = 'baseAssetUsdPrice',
  BaseFlow = 'baseFlow',
  BidTick = 'bidTick',
  Block = 'block',
  CallSource = 'callSource',
  ChangeType = 'changeType',
  Id = 'id',
  IsBid = 'isBid',
  Liq = 'liq',
  PivotTime = 'pivotTime',
  Pool = 'pool',
  PoolBase = 'pool__base',
  PoolBaseAmount = 'pool__baseAmount',
  PoolBgtApy = 'pool__bgtApy',
  PoolBlockCreate = 'pool__blockCreate',
  PoolId = 'pool__id',
  PoolPoolIdx = 'pool__poolIdx',
  PoolQuote = 'pool__quote',
  PoolQuoteAmount = 'pool__quoteAmount',
  PoolTimeCreate = 'pool__timeCreate',
  PoolTvlUsd = 'pool__tvlUsd',
  PositionType = 'positionType',
  QuoteAssetBeraPrice = 'quoteAssetBeraPrice',
  QuoteAssetUsdPrice = 'quoteAssetUsdPrice',
  QuoteFlow = 'quoteFlow',
  Time = 'time',
  TransactionHash = 'transactionHash',
  User = 'user',
}

/** Defines the order direction, either ascending or descending */
export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc',
}

/**
 * A Pool represents a single liquidity pool on CrocSwap, which is uniquely
 * specified by a base token, a quote token, and a poolIdx. The ID of the Pool
 * is the same as the poolHash used internally in CrocSwap contracts.
 *
 */
export type Pool = {
  __typename?: 'Pool';
  base: Scalars['Bytes'];
  baseAmount: Scalars['BigDecimal'];
  baseInfo: TokenInformation;
  bgtApy: Scalars['BigDecimal'];
  blockCreate: Scalars['BigInt'];
  id: Scalars['String'];
  latestPoolDayData?: Maybe<PoolDayData>;
  liquidityChanges: Array<LiquidityChange>;
  poolDayDatas: Array<PoolDayData>;
  poolIdx: Scalars['BigInt'];
  quote: Scalars['Bytes'];
  quoteAmount: Scalars['BigDecimal'];
  quoteInfo: TokenInformation;
  shareAddress: PoolShareAddress;
  swaps: Array<Swap>;
  template?: Maybe<PoolTemplate>;
  timeCreate: Scalars['BigInt'];
  tvlUsd: Scalars['BigDecimal'];
  vault?: Maybe<Vault>;
};

/**
 * A Pool represents a single liquidity pool on CrocSwap, which is uniquely
 * specified by a base token, a quote token, and a poolIdx. The ID of the Pool
 * is the same as the poolHash used internally in CrocSwap contracts.
 *
 */
export type PoolLiquidityChangesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LiquidityChange_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LiquidityChange_Filter>;
};

/**
 * A Pool represents a single liquidity pool on CrocSwap, which is uniquely
 * specified by a base token, a quote token, and a poolIdx. The ID of the Pool
 * is the same as the poolHash used internally in CrocSwap contracts.
 *
 */
export type PoolPoolDayDatasArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PoolDayData_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PoolDayData_Filter>;
};

/**
 * A Pool represents a single liquidity pool on CrocSwap, which is uniquely
 * specified by a base token, a quote token, and a poolIdx. The ID of the Pool
 * is the same as the poolHash used internally in CrocSwap contracts.
 *
 */
export type PoolSwapsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Swap_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Swap_Filter>;
};

export type PoolDayData = {
  __typename?: 'PoolDayData';
  date: Scalars['Int'];
  feesUsd: Scalars['BigDecimal'];
  id: Scalars['ID'];
  pool: Pool;
  tvlUsd: Scalars['BigDecimal'];
  txCount: Scalars['BigDecimal'];
  volumeUsd: Scalars['BigDecimal'];
};

export type PoolDayData_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PoolDayData_Filter>>>;
  date?: InputMaybe<Scalars['Int']>;
  date_gt?: InputMaybe<Scalars['Int']>;
  date_gte?: InputMaybe<Scalars['Int']>;
  date_in?: InputMaybe<Array<Scalars['Int']>>;
  date_lt?: InputMaybe<Scalars['Int']>;
  date_lte?: InputMaybe<Scalars['Int']>;
  date_not?: InputMaybe<Scalars['Int']>;
  date_not_in?: InputMaybe<Array<Scalars['Int']>>;
  feesUsd?: InputMaybe<Scalars['BigDecimal']>;
  feesUsd_gt?: InputMaybe<Scalars['BigDecimal']>;
  feesUsd_gte?: InputMaybe<Scalars['BigDecimal']>;
  feesUsd_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  feesUsd_lt?: InputMaybe<Scalars['BigDecimal']>;
  feesUsd_lte?: InputMaybe<Scalars['BigDecimal']>;
  feesUsd_not?: InputMaybe<Scalars['BigDecimal']>;
  feesUsd_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<PoolDayData_Filter>>>;
  pool?: InputMaybe<Scalars['String']>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars['String']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_ends_with?: InputMaybe<Scalars['String']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_gt?: InputMaybe<Scalars['String']>;
  pool_gte?: InputMaybe<Scalars['String']>;
  pool_in?: InputMaybe<Array<Scalars['String']>>;
  pool_lt?: InputMaybe<Scalars['String']>;
  pool_lte?: InputMaybe<Scalars['String']>;
  pool_not?: InputMaybe<Scalars['String']>;
  pool_not_contains?: InputMaybe<Scalars['String']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_not_in?: InputMaybe<Array<Scalars['String']>>;
  pool_not_starts_with?: InputMaybe<Scalars['String']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  pool_starts_with?: InputMaybe<Scalars['String']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']>;
  tvlUsd?: InputMaybe<Scalars['BigDecimal']>;
  tvlUsd_gt?: InputMaybe<Scalars['BigDecimal']>;
  tvlUsd_gte?: InputMaybe<Scalars['BigDecimal']>;
  tvlUsd_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  tvlUsd_lt?: InputMaybe<Scalars['BigDecimal']>;
  tvlUsd_lte?: InputMaybe<Scalars['BigDecimal']>;
  tvlUsd_not?: InputMaybe<Scalars['BigDecimal']>;
  tvlUsd_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  txCount?: InputMaybe<Scalars['BigDecimal']>;
  txCount_gt?: InputMaybe<Scalars['BigDecimal']>;
  txCount_gte?: InputMaybe<Scalars['BigDecimal']>;
  txCount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  txCount_lt?: InputMaybe<Scalars['BigDecimal']>;
  txCount_lte?: InputMaybe<Scalars['BigDecimal']>;
  txCount_not?: InputMaybe<Scalars['BigDecimal']>;
  txCount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volumeUsd?: InputMaybe<Scalars['BigDecimal']>;
  volumeUsd_gt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUsd_gte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUsd_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volumeUsd_lt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUsd_lte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUsd_not?: InputMaybe<Scalars['BigDecimal']>;
  volumeUsd_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum PoolDayData_OrderBy {
  Date = 'date',
  FeesUsd = 'feesUsd',
  Id = 'id',
  Pool = 'pool',
  PoolBase = 'pool__base',
  PoolBaseAmount = 'pool__baseAmount',
  PoolBgtApy = 'pool__bgtApy',
  PoolBlockCreate = 'pool__blockCreate',
  PoolId = 'pool__id',
  PoolPoolIdx = 'pool__poolIdx',
  PoolQuote = 'pool__quote',
  PoolQuoteAmount = 'pool__quoteAmount',
  PoolTimeCreate = 'pool__timeCreate',
  PoolTvlUsd = 'pool__tvlUsd',
  TvlUsd = 'tvlUsd',
  TxCount = 'txCount',
  VolumeUsd = 'volumeUsd',
}

export type PoolShareAddress = {
  __typename?: 'PoolShareAddress';
  address: Scalars['String'];
  id: Scalars['ID'];
  pool: Pool;
  vault?: Maybe<Vault>;
};

export type PoolShareAddress_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  address?: InputMaybe<Scalars['String']>;
  address_contains?: InputMaybe<Scalars['String']>;
  address_contains_nocase?: InputMaybe<Scalars['String']>;
  address_ends_with?: InputMaybe<Scalars['String']>;
  address_ends_with_nocase?: InputMaybe<Scalars['String']>;
  address_gt?: InputMaybe<Scalars['String']>;
  address_gte?: InputMaybe<Scalars['String']>;
  address_in?: InputMaybe<Array<Scalars['String']>>;
  address_lt?: InputMaybe<Scalars['String']>;
  address_lte?: InputMaybe<Scalars['String']>;
  address_not?: InputMaybe<Scalars['String']>;
  address_not_contains?: InputMaybe<Scalars['String']>;
  address_not_contains_nocase?: InputMaybe<Scalars['String']>;
  address_not_ends_with?: InputMaybe<Scalars['String']>;
  address_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  address_not_in?: InputMaybe<Array<Scalars['String']>>;
  address_not_starts_with?: InputMaybe<Scalars['String']>;
  address_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  address_starts_with?: InputMaybe<Scalars['String']>;
  address_starts_with_nocase?: InputMaybe<Scalars['String']>;
  and?: InputMaybe<Array<InputMaybe<PoolShareAddress_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<PoolShareAddress_Filter>>>;
  pool?: InputMaybe<Scalars['String']>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars['String']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_ends_with?: InputMaybe<Scalars['String']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_gt?: InputMaybe<Scalars['String']>;
  pool_gte?: InputMaybe<Scalars['String']>;
  pool_in?: InputMaybe<Array<Scalars['String']>>;
  pool_lt?: InputMaybe<Scalars['String']>;
  pool_lte?: InputMaybe<Scalars['String']>;
  pool_not?: InputMaybe<Scalars['String']>;
  pool_not_contains?: InputMaybe<Scalars['String']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_not_in?: InputMaybe<Array<Scalars['String']>>;
  pool_not_starts_with?: InputMaybe<Scalars['String']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  pool_starts_with?: InputMaybe<Scalars['String']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']>;
  vault?: InputMaybe<Scalars['String']>;
  vault_?: InputMaybe<Vault_Filter>;
  vault_contains?: InputMaybe<Scalars['String']>;
  vault_contains_nocase?: InputMaybe<Scalars['String']>;
  vault_ends_with?: InputMaybe<Scalars['String']>;
  vault_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vault_gt?: InputMaybe<Scalars['String']>;
  vault_gte?: InputMaybe<Scalars['String']>;
  vault_in?: InputMaybe<Array<Scalars['String']>>;
  vault_lt?: InputMaybe<Scalars['String']>;
  vault_lte?: InputMaybe<Scalars['String']>;
  vault_not?: InputMaybe<Scalars['String']>;
  vault_not_contains?: InputMaybe<Scalars['String']>;
  vault_not_contains_nocase?: InputMaybe<Scalars['String']>;
  vault_not_ends_with?: InputMaybe<Scalars['String']>;
  vault_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vault_not_in?: InputMaybe<Array<Scalars['String']>>;
  vault_not_starts_with?: InputMaybe<Scalars['String']>;
  vault_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  vault_starts_with?: InputMaybe<Scalars['String']>;
  vault_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum PoolShareAddress_OrderBy {
  Address = 'address',
  Id = 'id',
  Pool = 'pool',
  PoolBase = 'pool__base',
  PoolBaseAmount = 'pool__baseAmount',
  PoolBgtApy = 'pool__bgtApy',
  PoolBlockCreate = 'pool__blockCreate',
  PoolId = 'pool__id',
  PoolPoolIdx = 'pool__poolIdx',
  PoolQuote = 'pool__quote',
  PoolQuoteAmount = 'pool__quoteAmount',
  PoolTimeCreate = 'pool__timeCreate',
  PoolTvlUsd = 'pool__tvlUsd',
  Vault = 'vault',
  VaultId = 'vault__id',
  VaultStakingTokenAmount = 'vault__stakingTokenAmount',
  VaultVaultAddress = 'vault__vaultAddress',
}

/**
 * PoolTemplate represents a pool type pattern to create new pools
 *
 */
export type PoolTemplate = {
  __typename?: 'PoolTemplate';
  blockInit: Scalars['BigInt'];
  blockRevise: Scalars['BigInt'];
  enabled: Scalars['Boolean'];
  feeRate: Scalars['Int'];
  id: Scalars['Bytes'];
  poolIdx: Scalars['BigInt'];
  pools: Array<Pool>;
  timeInit: Scalars['BigInt'];
  timeRevise: Scalars['BigInt'];
};

/**
 * PoolTemplate represents a pool type pattern to create new pools
 *
 */
export type PoolTemplatePoolsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Pool_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Pool_Filter>;
};

export type PoolTemplate_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PoolTemplate_Filter>>>;
  blockInit?: InputMaybe<Scalars['BigInt']>;
  blockInit_gt?: InputMaybe<Scalars['BigInt']>;
  blockInit_gte?: InputMaybe<Scalars['BigInt']>;
  blockInit_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockInit_lt?: InputMaybe<Scalars['BigInt']>;
  blockInit_lte?: InputMaybe<Scalars['BigInt']>;
  blockInit_not?: InputMaybe<Scalars['BigInt']>;
  blockInit_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockRevise?: InputMaybe<Scalars['BigInt']>;
  blockRevise_gt?: InputMaybe<Scalars['BigInt']>;
  blockRevise_gte?: InputMaybe<Scalars['BigInt']>;
  blockRevise_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockRevise_lt?: InputMaybe<Scalars['BigInt']>;
  blockRevise_lte?: InputMaybe<Scalars['BigInt']>;
  blockRevise_not?: InputMaybe<Scalars['BigInt']>;
  blockRevise_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  enabled_in?: InputMaybe<Array<Scalars['Boolean']>>;
  enabled_not?: InputMaybe<Scalars['Boolean']>;
  enabled_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  feeRate?: InputMaybe<Scalars['Int']>;
  feeRate_gt?: InputMaybe<Scalars['Int']>;
  feeRate_gte?: InputMaybe<Scalars['Int']>;
  feeRate_in?: InputMaybe<Array<Scalars['Int']>>;
  feeRate_lt?: InputMaybe<Scalars['Int']>;
  feeRate_lte?: InputMaybe<Scalars['Int']>;
  feeRate_not?: InputMaybe<Scalars['Int']>;
  feeRate_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['Bytes']>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  or?: InputMaybe<Array<InputMaybe<PoolTemplate_Filter>>>;
  poolIdx?: InputMaybe<Scalars['BigInt']>;
  poolIdx_gt?: InputMaybe<Scalars['BigInt']>;
  poolIdx_gte?: InputMaybe<Scalars['BigInt']>;
  poolIdx_in?: InputMaybe<Array<Scalars['BigInt']>>;
  poolIdx_lt?: InputMaybe<Scalars['BigInt']>;
  poolIdx_lte?: InputMaybe<Scalars['BigInt']>;
  poolIdx_not?: InputMaybe<Scalars['BigInt']>;
  poolIdx_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  pools_?: InputMaybe<Pool_Filter>;
  timeInit?: InputMaybe<Scalars['BigInt']>;
  timeInit_gt?: InputMaybe<Scalars['BigInt']>;
  timeInit_gte?: InputMaybe<Scalars['BigInt']>;
  timeInit_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timeInit_lt?: InputMaybe<Scalars['BigInt']>;
  timeInit_lte?: InputMaybe<Scalars['BigInt']>;
  timeInit_not?: InputMaybe<Scalars['BigInt']>;
  timeInit_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timeRevise?: InputMaybe<Scalars['BigInt']>;
  timeRevise_gt?: InputMaybe<Scalars['BigInt']>;
  timeRevise_gte?: InputMaybe<Scalars['BigInt']>;
  timeRevise_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timeRevise_lt?: InputMaybe<Scalars['BigInt']>;
  timeRevise_lte?: InputMaybe<Scalars['BigInt']>;
  timeRevise_not?: InputMaybe<Scalars['BigInt']>;
  timeRevise_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum PoolTemplate_OrderBy {
  BlockInit = 'blockInit',
  BlockRevise = 'blockRevise',
  Enabled = 'enabled',
  FeeRate = 'feeRate',
  Id = 'id',
  PoolIdx = 'poolIdx',
  Pools = 'pools',
  TimeInit = 'timeInit',
  TimeRevise = 'timeRevise',
}

export type Pool_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Pool_Filter>>>;
  base?: InputMaybe<Scalars['Bytes']>;
  baseAmount?: InputMaybe<Scalars['BigDecimal']>;
  baseAmount_gt?: InputMaybe<Scalars['BigDecimal']>;
  baseAmount_gte?: InputMaybe<Scalars['BigDecimal']>;
  baseAmount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  baseAmount_lt?: InputMaybe<Scalars['BigDecimal']>;
  baseAmount_lte?: InputMaybe<Scalars['BigDecimal']>;
  baseAmount_not?: InputMaybe<Scalars['BigDecimal']>;
  baseAmount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  baseInfo?: InputMaybe<Scalars['String']>;
  baseInfo_?: InputMaybe<TokenInformation_Filter>;
  baseInfo_contains?: InputMaybe<Scalars['String']>;
  baseInfo_contains_nocase?: InputMaybe<Scalars['String']>;
  baseInfo_ends_with?: InputMaybe<Scalars['String']>;
  baseInfo_ends_with_nocase?: InputMaybe<Scalars['String']>;
  baseInfo_gt?: InputMaybe<Scalars['String']>;
  baseInfo_gte?: InputMaybe<Scalars['String']>;
  baseInfo_in?: InputMaybe<Array<Scalars['String']>>;
  baseInfo_lt?: InputMaybe<Scalars['String']>;
  baseInfo_lte?: InputMaybe<Scalars['String']>;
  baseInfo_not?: InputMaybe<Scalars['String']>;
  baseInfo_not_contains?: InputMaybe<Scalars['String']>;
  baseInfo_not_contains_nocase?: InputMaybe<Scalars['String']>;
  baseInfo_not_ends_with?: InputMaybe<Scalars['String']>;
  baseInfo_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  baseInfo_not_in?: InputMaybe<Array<Scalars['String']>>;
  baseInfo_not_starts_with?: InputMaybe<Scalars['String']>;
  baseInfo_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  baseInfo_starts_with?: InputMaybe<Scalars['String']>;
  baseInfo_starts_with_nocase?: InputMaybe<Scalars['String']>;
  base_contains?: InputMaybe<Scalars['Bytes']>;
  base_gt?: InputMaybe<Scalars['Bytes']>;
  base_gte?: InputMaybe<Scalars['Bytes']>;
  base_in?: InputMaybe<Array<Scalars['Bytes']>>;
  base_lt?: InputMaybe<Scalars['Bytes']>;
  base_lte?: InputMaybe<Scalars['Bytes']>;
  base_not?: InputMaybe<Scalars['Bytes']>;
  base_not_contains?: InputMaybe<Scalars['Bytes']>;
  base_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  bgtApy?: InputMaybe<Scalars['BigDecimal']>;
  bgtApy_gt?: InputMaybe<Scalars['BigDecimal']>;
  bgtApy_gte?: InputMaybe<Scalars['BigDecimal']>;
  bgtApy_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  bgtApy_lt?: InputMaybe<Scalars['BigDecimal']>;
  bgtApy_lte?: InputMaybe<Scalars['BigDecimal']>;
  bgtApy_not?: InputMaybe<Scalars['BigDecimal']>;
  bgtApy_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  blockCreate?: InputMaybe<Scalars['BigInt']>;
  blockCreate_gt?: InputMaybe<Scalars['BigInt']>;
  blockCreate_gte?: InputMaybe<Scalars['BigInt']>;
  blockCreate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockCreate_lt?: InputMaybe<Scalars['BigInt']>;
  blockCreate_lte?: InputMaybe<Scalars['BigInt']>;
  blockCreate_not?: InputMaybe<Scalars['BigInt']>;
  blockCreate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['String']>;
  id_contains?: InputMaybe<Scalars['String']>;
  id_contains_nocase?: InputMaybe<Scalars['String']>;
  id_ends_with?: InputMaybe<Scalars['String']>;
  id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  id_gt?: InputMaybe<Scalars['String']>;
  id_gte?: InputMaybe<Scalars['String']>;
  id_in?: InputMaybe<Array<Scalars['String']>>;
  id_lt?: InputMaybe<Scalars['String']>;
  id_lte?: InputMaybe<Scalars['String']>;
  id_not?: InputMaybe<Scalars['String']>;
  id_not_contains?: InputMaybe<Scalars['String']>;
  id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  id_not_ends_with?: InputMaybe<Scalars['String']>;
  id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  id_not_in?: InputMaybe<Array<Scalars['String']>>;
  id_not_starts_with?: InputMaybe<Scalars['String']>;
  id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id_starts_with?: InputMaybe<Scalars['String']>;
  id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  latestPoolDayData?: InputMaybe<Scalars['String']>;
  latestPoolDayData_?: InputMaybe<PoolDayData_Filter>;
  latestPoolDayData_contains?: InputMaybe<Scalars['String']>;
  latestPoolDayData_contains_nocase?: InputMaybe<Scalars['String']>;
  latestPoolDayData_ends_with?: InputMaybe<Scalars['String']>;
  latestPoolDayData_ends_with_nocase?: InputMaybe<Scalars['String']>;
  latestPoolDayData_gt?: InputMaybe<Scalars['String']>;
  latestPoolDayData_gte?: InputMaybe<Scalars['String']>;
  latestPoolDayData_in?: InputMaybe<Array<Scalars['String']>>;
  latestPoolDayData_lt?: InputMaybe<Scalars['String']>;
  latestPoolDayData_lte?: InputMaybe<Scalars['String']>;
  latestPoolDayData_not?: InputMaybe<Scalars['String']>;
  latestPoolDayData_not_contains?: InputMaybe<Scalars['String']>;
  latestPoolDayData_not_contains_nocase?: InputMaybe<Scalars['String']>;
  latestPoolDayData_not_ends_with?: InputMaybe<Scalars['String']>;
  latestPoolDayData_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  latestPoolDayData_not_in?: InputMaybe<Array<Scalars['String']>>;
  latestPoolDayData_not_starts_with?: InputMaybe<Scalars['String']>;
  latestPoolDayData_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  latestPoolDayData_starts_with?: InputMaybe<Scalars['String']>;
  latestPoolDayData_starts_with_nocase?: InputMaybe<Scalars['String']>;
  liquidityChanges_?: InputMaybe<LiquidityChange_Filter>;
  or?: InputMaybe<Array<InputMaybe<Pool_Filter>>>;
  poolDayDatas_?: InputMaybe<PoolDayData_Filter>;
  poolIdx?: InputMaybe<Scalars['BigInt']>;
  poolIdx_gt?: InputMaybe<Scalars['BigInt']>;
  poolIdx_gte?: InputMaybe<Scalars['BigInt']>;
  poolIdx_in?: InputMaybe<Array<Scalars['BigInt']>>;
  poolIdx_lt?: InputMaybe<Scalars['BigInt']>;
  poolIdx_lte?: InputMaybe<Scalars['BigInt']>;
  poolIdx_not?: InputMaybe<Scalars['BigInt']>;
  poolIdx_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  quote?: InputMaybe<Scalars['Bytes']>;
  quoteAmount?: InputMaybe<Scalars['BigDecimal']>;
  quoteAmount_gt?: InputMaybe<Scalars['BigDecimal']>;
  quoteAmount_gte?: InputMaybe<Scalars['BigDecimal']>;
  quoteAmount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  quoteAmount_lt?: InputMaybe<Scalars['BigDecimal']>;
  quoteAmount_lte?: InputMaybe<Scalars['BigDecimal']>;
  quoteAmount_not?: InputMaybe<Scalars['BigDecimal']>;
  quoteAmount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  quoteInfo?: InputMaybe<Scalars['String']>;
  quoteInfo_?: InputMaybe<TokenInformation_Filter>;
  quoteInfo_contains?: InputMaybe<Scalars['String']>;
  quoteInfo_contains_nocase?: InputMaybe<Scalars['String']>;
  quoteInfo_ends_with?: InputMaybe<Scalars['String']>;
  quoteInfo_ends_with_nocase?: InputMaybe<Scalars['String']>;
  quoteInfo_gt?: InputMaybe<Scalars['String']>;
  quoteInfo_gte?: InputMaybe<Scalars['String']>;
  quoteInfo_in?: InputMaybe<Array<Scalars['String']>>;
  quoteInfo_lt?: InputMaybe<Scalars['String']>;
  quoteInfo_lte?: InputMaybe<Scalars['String']>;
  quoteInfo_not?: InputMaybe<Scalars['String']>;
  quoteInfo_not_contains?: InputMaybe<Scalars['String']>;
  quoteInfo_not_contains_nocase?: InputMaybe<Scalars['String']>;
  quoteInfo_not_ends_with?: InputMaybe<Scalars['String']>;
  quoteInfo_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  quoteInfo_not_in?: InputMaybe<Array<Scalars['String']>>;
  quoteInfo_not_starts_with?: InputMaybe<Scalars['String']>;
  quoteInfo_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  quoteInfo_starts_with?: InputMaybe<Scalars['String']>;
  quoteInfo_starts_with_nocase?: InputMaybe<Scalars['String']>;
  quote_contains?: InputMaybe<Scalars['Bytes']>;
  quote_gt?: InputMaybe<Scalars['Bytes']>;
  quote_gte?: InputMaybe<Scalars['Bytes']>;
  quote_in?: InputMaybe<Array<Scalars['Bytes']>>;
  quote_lt?: InputMaybe<Scalars['Bytes']>;
  quote_lte?: InputMaybe<Scalars['Bytes']>;
  quote_not?: InputMaybe<Scalars['Bytes']>;
  quote_not_contains?: InputMaybe<Scalars['Bytes']>;
  quote_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  shareAddress_?: InputMaybe<PoolShareAddress_Filter>;
  swaps_?: InputMaybe<Swap_Filter>;
  template?: InputMaybe<Scalars['String']>;
  template_?: InputMaybe<PoolTemplate_Filter>;
  template_contains?: InputMaybe<Scalars['String']>;
  template_contains_nocase?: InputMaybe<Scalars['String']>;
  template_ends_with?: InputMaybe<Scalars['String']>;
  template_ends_with_nocase?: InputMaybe<Scalars['String']>;
  template_gt?: InputMaybe<Scalars['String']>;
  template_gte?: InputMaybe<Scalars['String']>;
  template_in?: InputMaybe<Array<Scalars['String']>>;
  template_lt?: InputMaybe<Scalars['String']>;
  template_lte?: InputMaybe<Scalars['String']>;
  template_not?: InputMaybe<Scalars['String']>;
  template_not_contains?: InputMaybe<Scalars['String']>;
  template_not_contains_nocase?: InputMaybe<Scalars['String']>;
  template_not_ends_with?: InputMaybe<Scalars['String']>;
  template_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  template_not_in?: InputMaybe<Array<Scalars['String']>>;
  template_not_starts_with?: InputMaybe<Scalars['String']>;
  template_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  template_starts_with?: InputMaybe<Scalars['String']>;
  template_starts_with_nocase?: InputMaybe<Scalars['String']>;
  timeCreate?: InputMaybe<Scalars['BigInt']>;
  timeCreate_gt?: InputMaybe<Scalars['BigInt']>;
  timeCreate_gte?: InputMaybe<Scalars['BigInt']>;
  timeCreate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timeCreate_lt?: InputMaybe<Scalars['BigInt']>;
  timeCreate_lte?: InputMaybe<Scalars['BigInt']>;
  timeCreate_not?: InputMaybe<Scalars['BigInt']>;
  timeCreate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tvlUsd?: InputMaybe<Scalars['BigDecimal']>;
  tvlUsd_gt?: InputMaybe<Scalars['BigDecimal']>;
  tvlUsd_gte?: InputMaybe<Scalars['BigDecimal']>;
  tvlUsd_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  tvlUsd_lt?: InputMaybe<Scalars['BigDecimal']>;
  tvlUsd_lte?: InputMaybe<Scalars['BigDecimal']>;
  tvlUsd_not?: InputMaybe<Scalars['BigDecimal']>;
  tvlUsd_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  vault?: InputMaybe<Scalars['String']>;
  vault_?: InputMaybe<Vault_Filter>;
  vault_contains?: InputMaybe<Scalars['String']>;
  vault_contains_nocase?: InputMaybe<Scalars['String']>;
  vault_ends_with?: InputMaybe<Scalars['String']>;
  vault_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vault_gt?: InputMaybe<Scalars['String']>;
  vault_gte?: InputMaybe<Scalars['String']>;
  vault_in?: InputMaybe<Array<Scalars['String']>>;
  vault_lt?: InputMaybe<Scalars['String']>;
  vault_lte?: InputMaybe<Scalars['String']>;
  vault_not?: InputMaybe<Scalars['String']>;
  vault_not_contains?: InputMaybe<Scalars['String']>;
  vault_not_contains_nocase?: InputMaybe<Scalars['String']>;
  vault_not_ends_with?: InputMaybe<Scalars['String']>;
  vault_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vault_not_in?: InputMaybe<Array<Scalars['String']>>;
  vault_not_starts_with?: InputMaybe<Scalars['String']>;
  vault_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  vault_starts_with?: InputMaybe<Scalars['String']>;
  vault_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum Pool_OrderBy {
  Base = 'base',
  BaseAmount = 'baseAmount',
  BaseInfo = 'baseInfo',
  BaseInfoAddress = 'baseInfo__address',
  BaseInfoBeraValue = 'baseInfo__beraValue',
  BaseInfoDecimals = 'baseInfo__decimals',
  BaseInfoId = 'baseInfo__id',
  BaseInfoName = 'baseInfo__name',
  BaseInfoSymbol = 'baseInfo__symbol',
  BaseInfoUsdValue = 'baseInfo__usdValue',
  BgtApy = 'bgtApy',
  BlockCreate = 'blockCreate',
  Id = 'id',
  LatestPoolDayData = 'latestPoolDayData',
  LatestPoolDayDataDate = 'latestPoolDayData__date',
  LatestPoolDayDataFeesUsd = 'latestPoolDayData__feesUsd',
  LatestPoolDayDataId = 'latestPoolDayData__id',
  LatestPoolDayDataTvlUsd = 'latestPoolDayData__tvlUsd',
  LatestPoolDayDataTxCount = 'latestPoolDayData__txCount',
  LatestPoolDayDataVolumeUsd = 'latestPoolDayData__volumeUsd',
  LiquidityChanges = 'liquidityChanges',
  PoolDayDatas = 'poolDayDatas',
  PoolIdx = 'poolIdx',
  Quote = 'quote',
  QuoteAmount = 'quoteAmount',
  QuoteInfo = 'quoteInfo',
  QuoteInfoAddress = 'quoteInfo__address',
  QuoteInfoBeraValue = 'quoteInfo__beraValue',
  QuoteInfoDecimals = 'quoteInfo__decimals',
  QuoteInfoId = 'quoteInfo__id',
  QuoteInfoName = 'quoteInfo__name',
  QuoteInfoSymbol = 'quoteInfo__symbol',
  QuoteInfoUsdValue = 'quoteInfo__usdValue',
  ShareAddress = 'shareAddress',
  ShareAddressAddress = 'shareAddress__address',
  ShareAddressId = 'shareAddress__id',
  Swaps = 'swaps',
  Template = 'template',
  TemplateBlockInit = 'template__blockInit',
  TemplateBlockRevise = 'template__blockRevise',
  TemplateEnabled = 'template__enabled',
  TemplateFeeRate = 'template__feeRate',
  TemplateId = 'template__id',
  TemplatePoolIdx = 'template__poolIdx',
  TemplateTimeInit = 'template__timeInit',
  TemplateTimeRevise = 'template__timeRevise',
  TimeCreate = 'timeCreate',
  TvlUsd = 'tvlUsd',
  Vault = 'vault',
  VaultId = 'vault__id',
  VaultStakingTokenAmount = 'vault__stakingTokenAmount',
  VaultVaultAddress = 'vault__vaultAddress',
}

export type Query = {
  __typename?: 'Query';
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  activeIncentive?: Maybe<ActiveIncentive>;
  activeIncentives: Array<ActiveIncentive>;
  allTimeValidatorIncentivesData?: Maybe<AllTimeValidatorIncentivesData>;
  allTimeValidatorIncentivesDatas: Array<AllTimeValidatorIncentivesData>;
  allTimeValidatorTokenReward?: Maybe<AllTimeValidatorTokenReward>;
  allTimeValidatorTokenRewards: Array<AllTimeValidatorTokenReward>;
  allTimeVaultData?: Maybe<AllTimeVaultData>;
  allTimeVaultDatas: Array<AllTimeVaultData>;
  allTimeVaultTokenEmissions?: Maybe<AllTimeVaultTokenEmissions>;
  allTimeVaultTokenEmissions_collection: Array<AllTimeVaultTokenEmissions>;
  bexGlobalData?: Maybe<BexGlobalData>;
  bexGlobalDatas: Array<BexGlobalData>;
  bexGlobalDayData?: Maybe<BexGlobalDayData>;
  bexGlobalDayDatas: Array<BexGlobalDayData>;
  dailyValidatorIncentivesData?: Maybe<DailyValidatorIncentivesData>;
  dailyValidatorIncentivesDatas: Array<DailyValidatorIncentivesData>;
  dailyValidatorTokenReward?: Maybe<DailyValidatorTokenReward>;
  dailyValidatorTokenRewards: Array<DailyValidatorTokenReward>;
  dailyVaultData?: Maybe<DailyVaultData>;
  dailyVaultDatas: Array<DailyVaultData>;
  dailyVaultTokenEmissions?: Maybe<DailyVaultTokenEmissions>;
  dailyVaultTokenEmissions_collection: Array<DailyVaultTokenEmissions>;
  defaultCuttingBoard?: Maybe<DefaultCuttingBoard>;
  defaultCuttingBoardWeight?: Maybe<DefaultCuttingBoardWeight>;
  defaultCuttingBoardWeights: Array<DefaultCuttingBoardWeight>;
  defaultCuttingBoards: Array<DefaultCuttingBoard>;
  feeChange?: Maybe<FeeChange>;
  feeChanges: Array<FeeChange>;
  friendsOfTheChef?: Maybe<FriendsOfTheChef>;
  friendsOfTheChefs: Array<FriendsOfTheChef>;
  globalCuttingBoardWeight?: Maybe<GlobalCuttingBoardWeight>;
  globalCuttingBoardWeights: Array<GlobalCuttingBoardWeight>;
  globalInfo?: Maybe<GlobalInfo>;
  globalInfos: Array<GlobalInfo>;
  liquidityChange?: Maybe<LiquidityChange>;
  liquidityChanges: Array<LiquidityChange>;
  pool?: Maybe<Pool>;
  poolDayData?: Maybe<PoolDayData>;
  poolDayDatas: Array<PoolDayData>;
  poolShareAddress?: Maybe<PoolShareAddress>;
  poolShareAddresses: Array<PoolShareAddress>;
  poolTemplate?: Maybe<PoolTemplate>;
  poolTemplates: Array<PoolTemplate>;
  pools: Array<Pool>;
  swap?: Maybe<Swap>;
  swaps: Array<Swap>;
  tokenInformation?: Maybe<TokenInformation>;
  tokenInformations: Array<TokenInformation>;
  userDepositedPools?: Maybe<UserDepositedPools>;
  userDepositedPools_collection: Array<UserDepositedPools>;
  userPools?: Maybe<UserPools>;
  userPools_collection: Array<UserPools>;
  userValidatorInformation?: Maybe<UserValidatorInformation>;
  userValidatorInformations: Array<UserValidatorInformation>;
  userVaultDeposits?: Maybe<UserVaultDeposits>;
  userVaultDeposits_collection: Array<UserVaultDeposits>;
  validator?: Maybe<Validator>;
  validatorCuttingBoard?: Maybe<ValidatorCuttingBoard>;
  validatorCuttingBoardWeight?: Maybe<ValidatorCuttingBoardWeight>;
  validatorCuttingBoardWeights: Array<ValidatorCuttingBoardWeight>;
  validatorCuttingBoards: Array<ValidatorCuttingBoard>;
  validators: Array<Validator>;
  vault?: Maybe<Vault>;
  vaultWhiteList?: Maybe<VaultWhiteList>;
  vaultWhiteListToken?: Maybe<VaultWhiteListToken>;
  vaultWhiteListTokens: Array<VaultWhiteListToken>;
  vaultWhiteLists: Array<VaultWhiteList>;
  vaults: Array<Vault>;
};

export type Query_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};

export type QueryActiveIncentiveArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryActiveIncentivesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ActiveIncentive_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ActiveIncentive_Filter>;
};

export type QueryAllTimeValidatorIncentivesDataArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryAllTimeValidatorIncentivesDatasArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AllTimeValidatorIncentivesData_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<AllTimeValidatorIncentivesData_Filter>;
};

export type QueryAllTimeValidatorTokenRewardArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryAllTimeValidatorTokenRewardsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AllTimeValidatorTokenReward_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<AllTimeValidatorTokenReward_Filter>;
};

export type QueryAllTimeVaultDataArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryAllTimeVaultDatasArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AllTimeVaultData_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<AllTimeVaultData_Filter>;
};

export type QueryAllTimeVaultTokenEmissionsArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryAllTimeVaultTokenEmissions_CollectionArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AllTimeVaultTokenEmissions_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<AllTimeVaultTokenEmissions_Filter>;
};

export type QueryBexGlobalDataArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryBexGlobalDatasArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BexGlobalData_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<BexGlobalData_Filter>;
};

export type QueryBexGlobalDayDataArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryBexGlobalDayDatasArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BexGlobalDayData_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<BexGlobalDayData_Filter>;
};

export type QueryDailyValidatorIncentivesDataArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryDailyValidatorIncentivesDatasArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DailyValidatorIncentivesData_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DailyValidatorIncentivesData_Filter>;
};

export type QueryDailyValidatorTokenRewardArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryDailyValidatorTokenRewardsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DailyValidatorTokenReward_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DailyValidatorTokenReward_Filter>;
};

export type QueryDailyVaultDataArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryDailyVaultDatasArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DailyVaultData_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DailyVaultData_Filter>;
};

export type QueryDailyVaultTokenEmissionsArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryDailyVaultTokenEmissions_CollectionArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DailyVaultTokenEmissions_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DailyVaultTokenEmissions_Filter>;
};

export type QueryDefaultCuttingBoardArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryDefaultCuttingBoardWeightArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryDefaultCuttingBoardWeightsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DefaultCuttingBoardWeight_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DefaultCuttingBoardWeight_Filter>;
};

export type QueryDefaultCuttingBoardsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DefaultCuttingBoard_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DefaultCuttingBoard_Filter>;
};

export type QueryFeeChangeArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryFeeChangesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FeeChange_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<FeeChange_Filter>;
};

export type QueryFriendsOfTheChefArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryFriendsOfTheChefsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FriendsOfTheChef_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<FriendsOfTheChef_Filter>;
};

export type QueryGlobalCuttingBoardWeightArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryGlobalCuttingBoardWeightsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GlobalCuttingBoardWeight_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalCuttingBoardWeight_Filter>;
};

export type QueryGlobalInfoArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryGlobalInfosArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GlobalInfo_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalInfo_Filter>;
};

export type QueryLiquidityChangeArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryLiquidityChangesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LiquidityChange_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<LiquidityChange_Filter>;
};

export type QueryPoolArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryPoolDayDataArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryPoolDayDatasArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PoolDayData_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PoolDayData_Filter>;
};

export type QueryPoolShareAddressArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryPoolShareAddressesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PoolShareAddress_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PoolShareAddress_Filter>;
};

export type QueryPoolTemplateArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryPoolTemplatesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PoolTemplate_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PoolTemplate_Filter>;
};

export type QueryPoolsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Pool_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Pool_Filter>;
};

export type QuerySwapArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QuerySwapsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Swap_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Swap_Filter>;
};

export type QueryTokenInformationArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryTokenInformationsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TokenInformation_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TokenInformation_Filter>;
};

export type QueryUserDepositedPoolsArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryUserDepositedPools_CollectionArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserDepositedPools_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<UserDepositedPools_Filter>;
};

export type QueryUserPoolsArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryUserPools_CollectionArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserPools_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<UserPools_Filter>;
};

export type QueryUserValidatorInformationArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryUserValidatorInformationsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserValidatorInformation_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<UserValidatorInformation_Filter>;
};

export type QueryUserVaultDepositsArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryUserVaultDeposits_CollectionArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserVaultDeposits_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<UserVaultDeposits_Filter>;
};

export type QueryValidatorArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryValidatorCuttingBoardArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryValidatorCuttingBoardWeightArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryValidatorCuttingBoardWeightsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ValidatorCuttingBoardWeight_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ValidatorCuttingBoardWeight_Filter>;
};

export type QueryValidatorCuttingBoardsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ValidatorCuttingBoard_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ValidatorCuttingBoard_Filter>;
};

export type QueryValidatorsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Validator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Validator_Filter>;
};

export type QueryVaultArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryVaultWhiteListArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryVaultWhiteListTokenArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryVaultWhiteListTokensArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<VaultWhiteListToken_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<VaultWhiteListToken_Filter>;
};

export type QueryVaultWhiteListsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<VaultWhiteList_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<VaultWhiteList_Filter>;
};

export type QueryVaultsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Vault_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Vault_Filter>;
};

export type Subscription = {
  __typename?: 'Subscription';
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  activeIncentive?: Maybe<ActiveIncentive>;
  activeIncentives: Array<ActiveIncentive>;
  allTimeValidatorIncentivesData?: Maybe<AllTimeValidatorIncentivesData>;
  allTimeValidatorIncentivesDatas: Array<AllTimeValidatorIncentivesData>;
  allTimeValidatorTokenReward?: Maybe<AllTimeValidatorTokenReward>;
  allTimeValidatorTokenRewards: Array<AllTimeValidatorTokenReward>;
  allTimeVaultData?: Maybe<AllTimeVaultData>;
  allTimeVaultDatas: Array<AllTimeVaultData>;
  allTimeVaultTokenEmissions?: Maybe<AllTimeVaultTokenEmissions>;
  allTimeVaultTokenEmissions_collection: Array<AllTimeVaultTokenEmissions>;
  bexGlobalData?: Maybe<BexGlobalData>;
  bexGlobalDatas: Array<BexGlobalData>;
  bexGlobalDayData?: Maybe<BexGlobalDayData>;
  bexGlobalDayDatas: Array<BexGlobalDayData>;
  dailyValidatorIncentivesData?: Maybe<DailyValidatorIncentivesData>;
  dailyValidatorIncentivesDatas: Array<DailyValidatorIncentivesData>;
  dailyValidatorTokenReward?: Maybe<DailyValidatorTokenReward>;
  dailyValidatorTokenRewards: Array<DailyValidatorTokenReward>;
  dailyVaultData?: Maybe<DailyVaultData>;
  dailyVaultDatas: Array<DailyVaultData>;
  dailyVaultTokenEmissions?: Maybe<DailyVaultTokenEmissions>;
  dailyVaultTokenEmissions_collection: Array<DailyVaultTokenEmissions>;
  defaultCuttingBoard?: Maybe<DefaultCuttingBoard>;
  defaultCuttingBoardWeight?: Maybe<DefaultCuttingBoardWeight>;
  defaultCuttingBoardWeights: Array<DefaultCuttingBoardWeight>;
  defaultCuttingBoards: Array<DefaultCuttingBoard>;
  feeChange?: Maybe<FeeChange>;
  feeChanges: Array<FeeChange>;
  friendsOfTheChef?: Maybe<FriendsOfTheChef>;
  friendsOfTheChefs: Array<FriendsOfTheChef>;
  globalCuttingBoardWeight?: Maybe<GlobalCuttingBoardWeight>;
  globalCuttingBoardWeights: Array<GlobalCuttingBoardWeight>;
  globalInfo?: Maybe<GlobalInfo>;
  globalInfos: Array<GlobalInfo>;
  liquidityChange?: Maybe<LiquidityChange>;
  liquidityChanges: Array<LiquidityChange>;
  pool?: Maybe<Pool>;
  poolDayData?: Maybe<PoolDayData>;
  poolDayDatas: Array<PoolDayData>;
  poolShareAddress?: Maybe<PoolShareAddress>;
  poolShareAddresses: Array<PoolShareAddress>;
  poolTemplate?: Maybe<PoolTemplate>;
  poolTemplates: Array<PoolTemplate>;
  pools: Array<Pool>;
  swap?: Maybe<Swap>;
  swaps: Array<Swap>;
  tokenInformation?: Maybe<TokenInformation>;
  tokenInformations: Array<TokenInformation>;
  userDepositedPools?: Maybe<UserDepositedPools>;
  userDepositedPools_collection: Array<UserDepositedPools>;
  userPools?: Maybe<UserPools>;
  userPools_collection: Array<UserPools>;
  userValidatorInformation?: Maybe<UserValidatorInformation>;
  userValidatorInformations: Array<UserValidatorInformation>;
  userVaultDeposits?: Maybe<UserVaultDeposits>;
  userVaultDeposits_collection: Array<UserVaultDeposits>;
  validator?: Maybe<Validator>;
  validatorCuttingBoard?: Maybe<ValidatorCuttingBoard>;
  validatorCuttingBoardWeight?: Maybe<ValidatorCuttingBoardWeight>;
  validatorCuttingBoardWeights: Array<ValidatorCuttingBoardWeight>;
  validatorCuttingBoards: Array<ValidatorCuttingBoard>;
  validators: Array<Validator>;
  vault?: Maybe<Vault>;
  vaultWhiteList?: Maybe<VaultWhiteList>;
  vaultWhiteListToken?: Maybe<VaultWhiteListToken>;
  vaultWhiteListTokens: Array<VaultWhiteListToken>;
  vaultWhiteLists: Array<VaultWhiteList>;
  vaults: Array<Vault>;
};

export type Subscription_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};

export type SubscriptionActiveIncentiveArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionActiveIncentivesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ActiveIncentive_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ActiveIncentive_Filter>;
};

export type SubscriptionAllTimeValidatorIncentivesDataArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionAllTimeValidatorIncentivesDatasArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AllTimeValidatorIncentivesData_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<AllTimeValidatorIncentivesData_Filter>;
};

export type SubscriptionAllTimeValidatorTokenRewardArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionAllTimeValidatorTokenRewardsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AllTimeValidatorTokenReward_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<AllTimeValidatorTokenReward_Filter>;
};

export type SubscriptionAllTimeVaultDataArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionAllTimeVaultDatasArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AllTimeVaultData_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<AllTimeVaultData_Filter>;
};

export type SubscriptionAllTimeVaultTokenEmissionsArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionAllTimeVaultTokenEmissions_CollectionArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AllTimeVaultTokenEmissions_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<AllTimeVaultTokenEmissions_Filter>;
};

export type SubscriptionBexGlobalDataArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionBexGlobalDatasArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BexGlobalData_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<BexGlobalData_Filter>;
};

export type SubscriptionBexGlobalDayDataArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionBexGlobalDayDatasArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BexGlobalDayData_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<BexGlobalDayData_Filter>;
};

export type SubscriptionDailyValidatorIncentivesDataArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionDailyValidatorIncentivesDatasArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DailyValidatorIncentivesData_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DailyValidatorIncentivesData_Filter>;
};

export type SubscriptionDailyValidatorTokenRewardArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionDailyValidatorTokenRewardsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DailyValidatorTokenReward_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DailyValidatorTokenReward_Filter>;
};

export type SubscriptionDailyVaultDataArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionDailyVaultDatasArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DailyVaultData_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DailyVaultData_Filter>;
};

export type SubscriptionDailyVaultTokenEmissionsArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionDailyVaultTokenEmissions_CollectionArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DailyVaultTokenEmissions_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DailyVaultTokenEmissions_Filter>;
};

export type SubscriptionDefaultCuttingBoardArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionDefaultCuttingBoardWeightArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionDefaultCuttingBoardWeightsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DefaultCuttingBoardWeight_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DefaultCuttingBoardWeight_Filter>;
};

export type SubscriptionDefaultCuttingBoardsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DefaultCuttingBoard_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DefaultCuttingBoard_Filter>;
};

export type SubscriptionFeeChangeArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionFeeChangesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FeeChange_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<FeeChange_Filter>;
};

export type SubscriptionFriendsOfTheChefArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionFriendsOfTheChefsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FriendsOfTheChef_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<FriendsOfTheChef_Filter>;
};

export type SubscriptionGlobalCuttingBoardWeightArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionGlobalCuttingBoardWeightsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GlobalCuttingBoardWeight_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalCuttingBoardWeight_Filter>;
};

export type SubscriptionGlobalInfoArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionGlobalInfosArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GlobalInfo_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalInfo_Filter>;
};

export type SubscriptionLiquidityChangeArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionLiquidityChangesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LiquidityChange_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<LiquidityChange_Filter>;
};

export type SubscriptionPoolArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionPoolDayDataArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionPoolDayDatasArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PoolDayData_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PoolDayData_Filter>;
};

export type SubscriptionPoolShareAddressArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionPoolShareAddressesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PoolShareAddress_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PoolShareAddress_Filter>;
};

export type SubscriptionPoolTemplateArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionPoolTemplatesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PoolTemplate_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PoolTemplate_Filter>;
};

export type SubscriptionPoolsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Pool_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Pool_Filter>;
};

export type SubscriptionSwapArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionSwapsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Swap_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Swap_Filter>;
};

export type SubscriptionTokenInformationArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionTokenInformationsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TokenInformation_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TokenInformation_Filter>;
};

export type SubscriptionUserDepositedPoolsArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionUserDepositedPools_CollectionArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserDepositedPools_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<UserDepositedPools_Filter>;
};

export type SubscriptionUserPoolsArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionUserPools_CollectionArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserPools_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<UserPools_Filter>;
};

export type SubscriptionUserValidatorInformationArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionUserValidatorInformationsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserValidatorInformation_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<UserValidatorInformation_Filter>;
};

export type SubscriptionUserVaultDepositsArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionUserVaultDeposits_CollectionArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserVaultDeposits_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<UserVaultDeposits_Filter>;
};

export type SubscriptionValidatorArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionValidatorCuttingBoardArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionValidatorCuttingBoardWeightArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionValidatorCuttingBoardWeightsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ValidatorCuttingBoardWeight_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ValidatorCuttingBoardWeight_Filter>;
};

export type SubscriptionValidatorCuttingBoardsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ValidatorCuttingBoard_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ValidatorCuttingBoard_Filter>;
};

export type SubscriptionValidatorsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Validator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Validator_Filter>;
};

export type SubscriptionVaultArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionVaultWhiteListArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionVaultWhiteListTokenArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionVaultWhiteListTokensArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<VaultWhiteListToken_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<VaultWhiteListToken_Filter>;
};

export type SubscriptionVaultWhiteListsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<VaultWhiteList_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<VaultWhiteList_Filter>;
};

export type SubscriptionVaultsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Vault_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Vault_Filter>;
};

/**
 * A Swap entity represents an atomic swap of a base token for a quote token
 * made on either CrocSwap or Uniswap V3.
 *
 */
export type Swap = {
  __typename?: 'Swap';
  baseAssetBeraPrice?: Maybe<Scalars['BigDecimal']>;
  baseAssetUsdPrice?: Maybe<Scalars['BigDecimal']>;
  baseFlow: Scalars['BigInt'];
  block: Scalars['BigInt'];
  callSource: Scalars['String'];
  dex: Scalars['String'];
  id: Scalars['Bytes'];
  inBaseQty: Scalars['Boolean'];
  isBuy: Scalars['Boolean'];
  limitPrice?: Maybe<Scalars['BigDecimal']>;
  minOut?: Maybe<Scalars['BigInt']>;
  pool: Pool;
  price?: Maybe<Scalars['BigDecimal']>;
  qty: Scalars['BigInt'];
  quoteAssetBeraPrice?: Maybe<Scalars['BigDecimal']>;
  quoteAssetUsdPrice?: Maybe<Scalars['BigDecimal']>;
  quoteFlow: Scalars['BigInt'];
  time: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
  transactionIndex: Scalars['BigInt'];
  user: Scalars['Bytes'];
};

export type Swap_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Swap_Filter>>>;
  baseAssetBeraPrice?: InputMaybe<Scalars['BigDecimal']>;
  baseAssetBeraPrice_gt?: InputMaybe<Scalars['BigDecimal']>;
  baseAssetBeraPrice_gte?: InputMaybe<Scalars['BigDecimal']>;
  baseAssetBeraPrice_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  baseAssetBeraPrice_lt?: InputMaybe<Scalars['BigDecimal']>;
  baseAssetBeraPrice_lte?: InputMaybe<Scalars['BigDecimal']>;
  baseAssetBeraPrice_not?: InputMaybe<Scalars['BigDecimal']>;
  baseAssetBeraPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  baseAssetUsdPrice?: InputMaybe<Scalars['BigDecimal']>;
  baseAssetUsdPrice_gt?: InputMaybe<Scalars['BigDecimal']>;
  baseAssetUsdPrice_gte?: InputMaybe<Scalars['BigDecimal']>;
  baseAssetUsdPrice_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  baseAssetUsdPrice_lt?: InputMaybe<Scalars['BigDecimal']>;
  baseAssetUsdPrice_lte?: InputMaybe<Scalars['BigDecimal']>;
  baseAssetUsdPrice_not?: InputMaybe<Scalars['BigDecimal']>;
  baseAssetUsdPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  baseFlow?: InputMaybe<Scalars['BigInt']>;
  baseFlow_gt?: InputMaybe<Scalars['BigInt']>;
  baseFlow_gte?: InputMaybe<Scalars['BigInt']>;
  baseFlow_in?: InputMaybe<Array<Scalars['BigInt']>>;
  baseFlow_lt?: InputMaybe<Scalars['BigInt']>;
  baseFlow_lte?: InputMaybe<Scalars['BigInt']>;
  baseFlow_not?: InputMaybe<Scalars['BigInt']>;
  baseFlow_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  block?: InputMaybe<Scalars['BigInt']>;
  block_gt?: InputMaybe<Scalars['BigInt']>;
  block_gte?: InputMaybe<Scalars['BigInt']>;
  block_in?: InputMaybe<Array<Scalars['BigInt']>>;
  block_lt?: InputMaybe<Scalars['BigInt']>;
  block_lte?: InputMaybe<Scalars['BigInt']>;
  block_not?: InputMaybe<Scalars['BigInt']>;
  block_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  callSource?: InputMaybe<Scalars['String']>;
  callSource_contains?: InputMaybe<Scalars['String']>;
  callSource_contains_nocase?: InputMaybe<Scalars['String']>;
  callSource_ends_with?: InputMaybe<Scalars['String']>;
  callSource_ends_with_nocase?: InputMaybe<Scalars['String']>;
  callSource_gt?: InputMaybe<Scalars['String']>;
  callSource_gte?: InputMaybe<Scalars['String']>;
  callSource_in?: InputMaybe<Array<Scalars['String']>>;
  callSource_lt?: InputMaybe<Scalars['String']>;
  callSource_lte?: InputMaybe<Scalars['String']>;
  callSource_not?: InputMaybe<Scalars['String']>;
  callSource_not_contains?: InputMaybe<Scalars['String']>;
  callSource_not_contains_nocase?: InputMaybe<Scalars['String']>;
  callSource_not_ends_with?: InputMaybe<Scalars['String']>;
  callSource_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  callSource_not_in?: InputMaybe<Array<Scalars['String']>>;
  callSource_not_starts_with?: InputMaybe<Scalars['String']>;
  callSource_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  callSource_starts_with?: InputMaybe<Scalars['String']>;
  callSource_starts_with_nocase?: InputMaybe<Scalars['String']>;
  dex?: InputMaybe<Scalars['String']>;
  dex_contains?: InputMaybe<Scalars['String']>;
  dex_contains_nocase?: InputMaybe<Scalars['String']>;
  dex_ends_with?: InputMaybe<Scalars['String']>;
  dex_ends_with_nocase?: InputMaybe<Scalars['String']>;
  dex_gt?: InputMaybe<Scalars['String']>;
  dex_gte?: InputMaybe<Scalars['String']>;
  dex_in?: InputMaybe<Array<Scalars['String']>>;
  dex_lt?: InputMaybe<Scalars['String']>;
  dex_lte?: InputMaybe<Scalars['String']>;
  dex_not?: InputMaybe<Scalars['String']>;
  dex_not_contains?: InputMaybe<Scalars['String']>;
  dex_not_contains_nocase?: InputMaybe<Scalars['String']>;
  dex_not_ends_with?: InputMaybe<Scalars['String']>;
  dex_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  dex_not_in?: InputMaybe<Array<Scalars['String']>>;
  dex_not_starts_with?: InputMaybe<Scalars['String']>;
  dex_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  dex_starts_with?: InputMaybe<Scalars['String']>;
  dex_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Bytes']>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  inBaseQty?: InputMaybe<Scalars['Boolean']>;
  inBaseQty_in?: InputMaybe<Array<Scalars['Boolean']>>;
  inBaseQty_not?: InputMaybe<Scalars['Boolean']>;
  inBaseQty_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  isBuy?: InputMaybe<Scalars['Boolean']>;
  isBuy_in?: InputMaybe<Array<Scalars['Boolean']>>;
  isBuy_not?: InputMaybe<Scalars['Boolean']>;
  isBuy_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  limitPrice?: InputMaybe<Scalars['BigDecimal']>;
  limitPrice_gt?: InputMaybe<Scalars['BigDecimal']>;
  limitPrice_gte?: InputMaybe<Scalars['BigDecimal']>;
  limitPrice_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  limitPrice_lt?: InputMaybe<Scalars['BigDecimal']>;
  limitPrice_lte?: InputMaybe<Scalars['BigDecimal']>;
  limitPrice_not?: InputMaybe<Scalars['BigDecimal']>;
  limitPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  minOut?: InputMaybe<Scalars['BigInt']>;
  minOut_gt?: InputMaybe<Scalars['BigInt']>;
  minOut_gte?: InputMaybe<Scalars['BigInt']>;
  minOut_in?: InputMaybe<Array<Scalars['BigInt']>>;
  minOut_lt?: InputMaybe<Scalars['BigInt']>;
  minOut_lte?: InputMaybe<Scalars['BigInt']>;
  minOut_not?: InputMaybe<Scalars['BigInt']>;
  minOut_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  or?: InputMaybe<Array<InputMaybe<Swap_Filter>>>;
  pool?: InputMaybe<Scalars['String']>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars['String']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_ends_with?: InputMaybe<Scalars['String']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_gt?: InputMaybe<Scalars['String']>;
  pool_gte?: InputMaybe<Scalars['String']>;
  pool_in?: InputMaybe<Array<Scalars['String']>>;
  pool_lt?: InputMaybe<Scalars['String']>;
  pool_lte?: InputMaybe<Scalars['String']>;
  pool_not?: InputMaybe<Scalars['String']>;
  pool_not_contains?: InputMaybe<Scalars['String']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_not_in?: InputMaybe<Array<Scalars['String']>>;
  pool_not_starts_with?: InputMaybe<Scalars['String']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  pool_starts_with?: InputMaybe<Scalars['String']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['BigDecimal']>;
  price_gt?: InputMaybe<Scalars['BigDecimal']>;
  price_gte?: InputMaybe<Scalars['BigDecimal']>;
  price_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  price_lt?: InputMaybe<Scalars['BigDecimal']>;
  price_lte?: InputMaybe<Scalars['BigDecimal']>;
  price_not?: InputMaybe<Scalars['BigDecimal']>;
  price_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  qty?: InputMaybe<Scalars['BigInt']>;
  qty_gt?: InputMaybe<Scalars['BigInt']>;
  qty_gte?: InputMaybe<Scalars['BigInt']>;
  qty_in?: InputMaybe<Array<Scalars['BigInt']>>;
  qty_lt?: InputMaybe<Scalars['BigInt']>;
  qty_lte?: InputMaybe<Scalars['BigInt']>;
  qty_not?: InputMaybe<Scalars['BigInt']>;
  qty_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  quoteAssetBeraPrice?: InputMaybe<Scalars['BigDecimal']>;
  quoteAssetBeraPrice_gt?: InputMaybe<Scalars['BigDecimal']>;
  quoteAssetBeraPrice_gte?: InputMaybe<Scalars['BigDecimal']>;
  quoteAssetBeraPrice_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  quoteAssetBeraPrice_lt?: InputMaybe<Scalars['BigDecimal']>;
  quoteAssetBeraPrice_lte?: InputMaybe<Scalars['BigDecimal']>;
  quoteAssetBeraPrice_not?: InputMaybe<Scalars['BigDecimal']>;
  quoteAssetBeraPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  quoteAssetUsdPrice?: InputMaybe<Scalars['BigDecimal']>;
  quoteAssetUsdPrice_gt?: InputMaybe<Scalars['BigDecimal']>;
  quoteAssetUsdPrice_gte?: InputMaybe<Scalars['BigDecimal']>;
  quoteAssetUsdPrice_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  quoteAssetUsdPrice_lt?: InputMaybe<Scalars['BigDecimal']>;
  quoteAssetUsdPrice_lte?: InputMaybe<Scalars['BigDecimal']>;
  quoteAssetUsdPrice_not?: InputMaybe<Scalars['BigDecimal']>;
  quoteAssetUsdPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  quoteFlow?: InputMaybe<Scalars['BigInt']>;
  quoteFlow_gt?: InputMaybe<Scalars['BigInt']>;
  quoteFlow_gte?: InputMaybe<Scalars['BigInt']>;
  quoteFlow_in?: InputMaybe<Array<Scalars['BigInt']>>;
  quoteFlow_lt?: InputMaybe<Scalars['BigInt']>;
  quoteFlow_lte?: InputMaybe<Scalars['BigInt']>;
  quoteFlow_not?: InputMaybe<Scalars['BigInt']>;
  quoteFlow_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  time?: InputMaybe<Scalars['BigInt']>;
  time_gt?: InputMaybe<Scalars['BigInt']>;
  time_gte?: InputMaybe<Scalars['BigInt']>;
  time_in?: InputMaybe<Array<Scalars['BigInt']>>;
  time_lt?: InputMaybe<Scalars['BigInt']>;
  time_lte?: InputMaybe<Scalars['BigInt']>;
  time_not?: InputMaybe<Scalars['BigInt']>;
  time_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionIndex?: InputMaybe<Scalars['BigInt']>;
  transactionIndex_gt?: InputMaybe<Scalars['BigInt']>;
  transactionIndex_gte?: InputMaybe<Scalars['BigInt']>;
  transactionIndex_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionIndex_lt?: InputMaybe<Scalars['BigInt']>;
  transactionIndex_lte?: InputMaybe<Scalars['BigInt']>;
  transactionIndex_not?: InputMaybe<Scalars['BigInt']>;
  transactionIndex_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  user?: InputMaybe<Scalars['Bytes']>;
  user_contains?: InputMaybe<Scalars['Bytes']>;
  user_gt?: InputMaybe<Scalars['Bytes']>;
  user_gte?: InputMaybe<Scalars['Bytes']>;
  user_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_lt?: InputMaybe<Scalars['Bytes']>;
  user_lte?: InputMaybe<Scalars['Bytes']>;
  user_not?: InputMaybe<Scalars['Bytes']>;
  user_not_contains?: InputMaybe<Scalars['Bytes']>;
  user_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
};

export enum Swap_OrderBy {
  BaseAssetBeraPrice = 'baseAssetBeraPrice',
  BaseAssetUsdPrice = 'baseAssetUsdPrice',
  BaseFlow = 'baseFlow',
  Block = 'block',
  CallSource = 'callSource',
  Dex = 'dex',
  Id = 'id',
  InBaseQty = 'inBaseQty',
  IsBuy = 'isBuy',
  LimitPrice = 'limitPrice',
  MinOut = 'minOut',
  Pool = 'pool',
  PoolBase = 'pool__base',
  PoolBaseAmount = 'pool__baseAmount',
  PoolBgtApy = 'pool__bgtApy',
  PoolBlockCreate = 'pool__blockCreate',
  PoolId = 'pool__id',
  PoolPoolIdx = 'pool__poolIdx',
  PoolQuote = 'pool__quote',
  PoolQuoteAmount = 'pool__quoteAmount',
  PoolTimeCreate = 'pool__timeCreate',
  PoolTvlUsd = 'pool__tvlUsd',
  Price = 'price',
  Qty = 'qty',
  QuoteAssetBeraPrice = 'quoteAssetBeraPrice',
  QuoteAssetUsdPrice = 'quoteAssetUsdPrice',
  QuoteFlow = 'quoteFlow',
  Time = 'time',
  TransactionHash = 'transactionHash',
  TransactionIndex = 'transactionIndex',
  User = 'user',
}

export type TokenInformation = {
  __typename?: 'TokenInformation';
  address: Scalars['String'];
  beraValue: Scalars['BigDecimal'];
  decimals: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  symbol: Scalars['String'];
  usdValue: Scalars['BigDecimal'];
};

export type TokenInformation_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  address?: InputMaybe<Scalars['String']>;
  address_contains?: InputMaybe<Scalars['String']>;
  address_contains_nocase?: InputMaybe<Scalars['String']>;
  address_ends_with?: InputMaybe<Scalars['String']>;
  address_ends_with_nocase?: InputMaybe<Scalars['String']>;
  address_gt?: InputMaybe<Scalars['String']>;
  address_gte?: InputMaybe<Scalars['String']>;
  address_in?: InputMaybe<Array<Scalars['String']>>;
  address_lt?: InputMaybe<Scalars['String']>;
  address_lte?: InputMaybe<Scalars['String']>;
  address_not?: InputMaybe<Scalars['String']>;
  address_not_contains?: InputMaybe<Scalars['String']>;
  address_not_contains_nocase?: InputMaybe<Scalars['String']>;
  address_not_ends_with?: InputMaybe<Scalars['String']>;
  address_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  address_not_in?: InputMaybe<Array<Scalars['String']>>;
  address_not_starts_with?: InputMaybe<Scalars['String']>;
  address_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  address_starts_with?: InputMaybe<Scalars['String']>;
  address_starts_with_nocase?: InputMaybe<Scalars['String']>;
  and?: InputMaybe<Array<InputMaybe<TokenInformation_Filter>>>;
  beraValue?: InputMaybe<Scalars['BigDecimal']>;
  beraValue_gt?: InputMaybe<Scalars['BigDecimal']>;
  beraValue_gte?: InputMaybe<Scalars['BigDecimal']>;
  beraValue_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  beraValue_lt?: InputMaybe<Scalars['BigDecimal']>;
  beraValue_lte?: InputMaybe<Scalars['BigDecimal']>;
  beraValue_not?: InputMaybe<Scalars['BigDecimal']>;
  beraValue_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  decimals?: InputMaybe<Scalars['Int']>;
  decimals_gt?: InputMaybe<Scalars['Int']>;
  decimals_gte?: InputMaybe<Scalars['Int']>;
  decimals_in?: InputMaybe<Array<Scalars['Int']>>;
  decimals_lt?: InputMaybe<Scalars['Int']>;
  decimals_lte?: InputMaybe<Scalars['Int']>;
  decimals_not?: InputMaybe<Scalars['Int']>;
  decimals_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_contains_nocase?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<InputMaybe<TokenInformation_Filter>>>;
  symbol?: InputMaybe<Scalars['String']>;
  symbol_contains?: InputMaybe<Scalars['String']>;
  symbol_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_ends_with?: InputMaybe<Scalars['String']>;
  symbol_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_gt?: InputMaybe<Scalars['String']>;
  symbol_gte?: InputMaybe<Scalars['String']>;
  symbol_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_lt?: InputMaybe<Scalars['String']>;
  symbol_lte?: InputMaybe<Scalars['String']>;
  symbol_not?: InputMaybe<Scalars['String']>;
  symbol_not_contains?: InputMaybe<Scalars['String']>;
  symbol_not_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_not_starts_with?: InputMaybe<Scalars['String']>;
  symbol_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_starts_with?: InputMaybe<Scalars['String']>;
  symbol_starts_with_nocase?: InputMaybe<Scalars['String']>;
  usdValue?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_gt?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_gte?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  usdValue_lt?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_lte?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_not?: InputMaybe<Scalars['BigDecimal']>;
  usdValue_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum TokenInformation_OrderBy {
  Address = 'address',
  BeraValue = 'beraValue',
  Decimals = 'decimals',
  Id = 'id',
  Name = 'name',
  Symbol = 'symbol',
  UsdValue = 'usdValue',
}

export type UserDepositedPools = {
  __typename?: 'UserDepositedPools';
  id: Scalars['ID'];
  pool: Pool;
  user: UserPools;
};

export type UserDepositedPools_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<UserDepositedPools_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<UserDepositedPools_Filter>>>;
  pool?: InputMaybe<Scalars['String']>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars['String']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_ends_with?: InputMaybe<Scalars['String']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_gt?: InputMaybe<Scalars['String']>;
  pool_gte?: InputMaybe<Scalars['String']>;
  pool_in?: InputMaybe<Array<Scalars['String']>>;
  pool_lt?: InputMaybe<Scalars['String']>;
  pool_lte?: InputMaybe<Scalars['String']>;
  pool_not?: InputMaybe<Scalars['String']>;
  pool_not_contains?: InputMaybe<Scalars['String']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_not_in?: InputMaybe<Array<Scalars['String']>>;
  pool_not_starts_with?: InputMaybe<Scalars['String']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  pool_starts_with?: InputMaybe<Scalars['String']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
  user_?: InputMaybe<UserPools_Filter>;
  user_contains?: InputMaybe<Scalars['String']>;
  user_contains_nocase?: InputMaybe<Scalars['String']>;
  user_ends_with?: InputMaybe<Scalars['String']>;
  user_ends_with_nocase?: InputMaybe<Scalars['String']>;
  user_gt?: InputMaybe<Scalars['String']>;
  user_gte?: InputMaybe<Scalars['String']>;
  user_in?: InputMaybe<Array<Scalars['String']>>;
  user_lt?: InputMaybe<Scalars['String']>;
  user_lte?: InputMaybe<Scalars['String']>;
  user_not?: InputMaybe<Scalars['String']>;
  user_not_contains?: InputMaybe<Scalars['String']>;
  user_not_contains_nocase?: InputMaybe<Scalars['String']>;
  user_not_ends_with?: InputMaybe<Scalars['String']>;
  user_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  user_not_in?: InputMaybe<Array<Scalars['String']>>;
  user_not_starts_with?: InputMaybe<Scalars['String']>;
  user_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  user_starts_with?: InputMaybe<Scalars['String']>;
  user_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum UserDepositedPools_OrderBy {
  Id = 'id',
  Pool = 'pool',
  PoolBase = 'pool__base',
  PoolBaseAmount = 'pool__baseAmount',
  PoolBgtApy = 'pool__bgtApy',
  PoolBlockCreate = 'pool__blockCreate',
  PoolId = 'pool__id',
  PoolPoolIdx = 'pool__poolIdx',
  PoolQuote = 'pool__quote',
  PoolQuoteAmount = 'pool__quoteAmount',
  PoolTimeCreate = 'pool__timeCreate',
  PoolTvlUsd = 'pool__tvlUsd',
  User = 'user',
  UserId = 'user__id',
  UserUser = 'user__user',
}

export type UserPools = {
  __typename?: 'UserPools';
  depositedPools: Array<UserDepositedPools>;
  id: Scalars['ID'];
  user: Scalars['Bytes'];
};

export type UserPoolsDepositedPoolsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserDepositedPools_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserDepositedPools_Filter>;
};

export type UserPools_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<UserPools_Filter>>>;
  depositedPools_?: InputMaybe<UserDepositedPools_Filter>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<UserPools_Filter>>>;
  user?: InputMaybe<Scalars['Bytes']>;
  user_contains?: InputMaybe<Scalars['Bytes']>;
  user_gt?: InputMaybe<Scalars['Bytes']>;
  user_gte?: InputMaybe<Scalars['Bytes']>;
  user_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_lt?: InputMaybe<Scalars['Bytes']>;
  user_lte?: InputMaybe<Scalars['Bytes']>;
  user_not?: InputMaybe<Scalars['Bytes']>;
  user_not_contains?: InputMaybe<Scalars['Bytes']>;
  user_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
};

export enum UserPools_OrderBy {
  DepositedPools = 'depositedPools',
  Id = 'id',
  User = 'user',
}

export type UserValidatorInformation = {
  __typename?: 'UserValidatorInformation';
  amountDeposited: Scalars['BigDecimal'];
  amountQueued: Scalars['BigDecimal'];
  coinbase: Scalars['Bytes'];
  id: Scalars['ID'];
  latestBlock: Scalars['BigInt'];
  latestBlockTime: Scalars['BigInt'];
  user: Scalars['Bytes'];
};

export type UserValidatorInformation_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  amountDeposited?: InputMaybe<Scalars['BigDecimal']>;
  amountDeposited_gt?: InputMaybe<Scalars['BigDecimal']>;
  amountDeposited_gte?: InputMaybe<Scalars['BigDecimal']>;
  amountDeposited_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  amountDeposited_lt?: InputMaybe<Scalars['BigDecimal']>;
  amountDeposited_lte?: InputMaybe<Scalars['BigDecimal']>;
  amountDeposited_not?: InputMaybe<Scalars['BigDecimal']>;
  amountDeposited_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  amountQueued?: InputMaybe<Scalars['BigDecimal']>;
  amountQueued_gt?: InputMaybe<Scalars['BigDecimal']>;
  amountQueued_gte?: InputMaybe<Scalars['BigDecimal']>;
  amountQueued_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  amountQueued_lt?: InputMaybe<Scalars['BigDecimal']>;
  amountQueued_lte?: InputMaybe<Scalars['BigDecimal']>;
  amountQueued_not?: InputMaybe<Scalars['BigDecimal']>;
  amountQueued_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  and?: InputMaybe<Array<InputMaybe<UserValidatorInformation_Filter>>>;
  coinbase?: InputMaybe<Scalars['Bytes']>;
  coinbase_contains?: InputMaybe<Scalars['Bytes']>;
  coinbase_gt?: InputMaybe<Scalars['Bytes']>;
  coinbase_gte?: InputMaybe<Scalars['Bytes']>;
  coinbase_in?: InputMaybe<Array<Scalars['Bytes']>>;
  coinbase_lt?: InputMaybe<Scalars['Bytes']>;
  coinbase_lte?: InputMaybe<Scalars['Bytes']>;
  coinbase_not?: InputMaybe<Scalars['Bytes']>;
  coinbase_not_contains?: InputMaybe<Scalars['Bytes']>;
  coinbase_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  latestBlock?: InputMaybe<Scalars['BigInt']>;
  latestBlockTime?: InputMaybe<Scalars['BigInt']>;
  latestBlockTime_gt?: InputMaybe<Scalars['BigInt']>;
  latestBlockTime_gte?: InputMaybe<Scalars['BigInt']>;
  latestBlockTime_in?: InputMaybe<Array<Scalars['BigInt']>>;
  latestBlockTime_lt?: InputMaybe<Scalars['BigInt']>;
  latestBlockTime_lte?: InputMaybe<Scalars['BigInt']>;
  latestBlockTime_not?: InputMaybe<Scalars['BigInt']>;
  latestBlockTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  latestBlock_gt?: InputMaybe<Scalars['BigInt']>;
  latestBlock_gte?: InputMaybe<Scalars['BigInt']>;
  latestBlock_in?: InputMaybe<Array<Scalars['BigInt']>>;
  latestBlock_lt?: InputMaybe<Scalars['BigInt']>;
  latestBlock_lte?: InputMaybe<Scalars['BigInt']>;
  latestBlock_not?: InputMaybe<Scalars['BigInt']>;
  latestBlock_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  or?: InputMaybe<Array<InputMaybe<UserValidatorInformation_Filter>>>;
  user?: InputMaybe<Scalars['Bytes']>;
  user_contains?: InputMaybe<Scalars['Bytes']>;
  user_gt?: InputMaybe<Scalars['Bytes']>;
  user_gte?: InputMaybe<Scalars['Bytes']>;
  user_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_lt?: InputMaybe<Scalars['Bytes']>;
  user_lte?: InputMaybe<Scalars['Bytes']>;
  user_not?: InputMaybe<Scalars['Bytes']>;
  user_not_contains?: InputMaybe<Scalars['Bytes']>;
  user_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
};

export enum UserValidatorInformation_OrderBy {
  AmountDeposited = 'amountDeposited',
  AmountQueued = 'amountQueued',
  Coinbase = 'coinbase',
  Id = 'id',
  LatestBlock = 'latestBlock',
  LatestBlockTime = 'latestBlockTime',
  User = 'user',
}

export type UserVaultDeposits = {
  __typename?: 'UserVaultDeposits';
  amount: Scalars['BigDecimal'];
  id: Scalars['ID'];
  user: Scalars['Bytes'];
  vault: Vault;
};

export type UserVaultDeposits_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  amount?: InputMaybe<Scalars['BigDecimal']>;
  amount_gt?: InputMaybe<Scalars['BigDecimal']>;
  amount_gte?: InputMaybe<Scalars['BigDecimal']>;
  amount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  amount_lt?: InputMaybe<Scalars['BigDecimal']>;
  amount_lte?: InputMaybe<Scalars['BigDecimal']>;
  amount_not?: InputMaybe<Scalars['BigDecimal']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  and?: InputMaybe<Array<InputMaybe<UserVaultDeposits_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<UserVaultDeposits_Filter>>>;
  user?: InputMaybe<Scalars['Bytes']>;
  user_contains?: InputMaybe<Scalars['Bytes']>;
  user_gt?: InputMaybe<Scalars['Bytes']>;
  user_gte?: InputMaybe<Scalars['Bytes']>;
  user_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_lt?: InputMaybe<Scalars['Bytes']>;
  user_lte?: InputMaybe<Scalars['Bytes']>;
  user_not?: InputMaybe<Scalars['Bytes']>;
  user_not_contains?: InputMaybe<Scalars['Bytes']>;
  user_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  vault?: InputMaybe<Scalars['String']>;
  vault_?: InputMaybe<Vault_Filter>;
  vault_contains?: InputMaybe<Scalars['String']>;
  vault_contains_nocase?: InputMaybe<Scalars['String']>;
  vault_ends_with?: InputMaybe<Scalars['String']>;
  vault_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vault_gt?: InputMaybe<Scalars['String']>;
  vault_gte?: InputMaybe<Scalars['String']>;
  vault_in?: InputMaybe<Array<Scalars['String']>>;
  vault_lt?: InputMaybe<Scalars['String']>;
  vault_lte?: InputMaybe<Scalars['String']>;
  vault_not?: InputMaybe<Scalars['String']>;
  vault_not_contains?: InputMaybe<Scalars['String']>;
  vault_not_contains_nocase?: InputMaybe<Scalars['String']>;
  vault_not_ends_with?: InputMaybe<Scalars['String']>;
  vault_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vault_not_in?: InputMaybe<Array<Scalars['String']>>;
  vault_not_starts_with?: InputMaybe<Scalars['String']>;
  vault_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  vault_starts_with?: InputMaybe<Scalars['String']>;
  vault_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum UserVaultDeposits_OrderBy {
  Amount = 'amount',
  Id = 'id',
  User = 'user',
  Vault = 'vault',
  VaultId = 'vault__id',
  VaultStakingTokenAmount = 'vault__stakingTokenAmount',
  VaultVaultAddress = 'vault__vaultAddress',
}

export type Validator = {
  __typename?: 'Validator';
  allTimeValidatorIncentivesData?: Maybe<AllTimeValidatorIncentivesData>;
  amountQueued: Scalars['BigDecimal'];
  amountStaked: Scalars['BigDecimal'];
  coinbase: Scalars['Bytes'];
  commission: Scalars['BigDecimal'];
  dailyValidatorIncentivesData?: Maybe<Array<DailyValidatorIncentivesData>>;
  id: Scalars['ID'];
  validatorCuttingBoard?: Maybe<ValidatorCuttingBoard>;
};

export type ValidatorDailyValidatorIncentivesDataArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DailyValidatorIncentivesData_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DailyValidatorIncentivesData_Filter>;
};

export type ValidatorCuttingBoard = {
  __typename?: 'ValidatorCuttingBoard';
  id: Scalars['ID'];
  startBlock: Scalars['BigInt'];
  validator: Validator;
  weights: Array<ValidatorCuttingBoardWeight>;
};

export type ValidatorCuttingBoardWeightsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ValidatorCuttingBoardWeight_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ValidatorCuttingBoardWeight_Filter>;
};

export type ValidatorCuttingBoardWeight = {
  __typename?: 'ValidatorCuttingBoardWeight';
  id: Scalars['ID'];
  owner: ValidatorCuttingBoard;
  percentageNumerator: Scalars['BigInt'];
  receiver: Scalars['Bytes'];
  vault?: Maybe<Vault>;
};

export type ValidatorCuttingBoardWeight_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ValidatorCuttingBoardWeight_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<ValidatorCuttingBoardWeight_Filter>>>;
  owner?: InputMaybe<Scalars['String']>;
  owner_?: InputMaybe<ValidatorCuttingBoard_Filter>;
  owner_contains?: InputMaybe<Scalars['String']>;
  owner_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_ends_with?: InputMaybe<Scalars['String']>;
  owner_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_gt?: InputMaybe<Scalars['String']>;
  owner_gte?: InputMaybe<Scalars['String']>;
  owner_in?: InputMaybe<Array<Scalars['String']>>;
  owner_lt?: InputMaybe<Scalars['String']>;
  owner_lte?: InputMaybe<Scalars['String']>;
  owner_not?: InputMaybe<Scalars['String']>;
  owner_not_contains?: InputMaybe<Scalars['String']>;
  owner_not_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  owner_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_not_in?: InputMaybe<Array<Scalars['String']>>;
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  owner_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  owner_starts_with?: InputMaybe<Scalars['String']>;
  owner_starts_with_nocase?: InputMaybe<Scalars['String']>;
  percentageNumerator?: InputMaybe<Scalars['BigInt']>;
  percentageNumerator_gt?: InputMaybe<Scalars['BigInt']>;
  percentageNumerator_gte?: InputMaybe<Scalars['BigInt']>;
  percentageNumerator_in?: InputMaybe<Array<Scalars['BigInt']>>;
  percentageNumerator_lt?: InputMaybe<Scalars['BigInt']>;
  percentageNumerator_lte?: InputMaybe<Scalars['BigInt']>;
  percentageNumerator_not?: InputMaybe<Scalars['BigInt']>;
  percentageNumerator_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  receiver?: InputMaybe<Scalars['Bytes']>;
  receiver_contains?: InputMaybe<Scalars['Bytes']>;
  receiver_gt?: InputMaybe<Scalars['Bytes']>;
  receiver_gte?: InputMaybe<Scalars['Bytes']>;
  receiver_in?: InputMaybe<Array<Scalars['Bytes']>>;
  receiver_lt?: InputMaybe<Scalars['Bytes']>;
  receiver_lte?: InputMaybe<Scalars['Bytes']>;
  receiver_not?: InputMaybe<Scalars['Bytes']>;
  receiver_not_contains?: InputMaybe<Scalars['Bytes']>;
  receiver_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  vault?: InputMaybe<Scalars['String']>;
  vault_?: InputMaybe<Vault_Filter>;
  vault_contains?: InputMaybe<Scalars['String']>;
  vault_contains_nocase?: InputMaybe<Scalars['String']>;
  vault_ends_with?: InputMaybe<Scalars['String']>;
  vault_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vault_gt?: InputMaybe<Scalars['String']>;
  vault_gte?: InputMaybe<Scalars['String']>;
  vault_in?: InputMaybe<Array<Scalars['String']>>;
  vault_lt?: InputMaybe<Scalars['String']>;
  vault_lte?: InputMaybe<Scalars['String']>;
  vault_not?: InputMaybe<Scalars['String']>;
  vault_not_contains?: InputMaybe<Scalars['String']>;
  vault_not_contains_nocase?: InputMaybe<Scalars['String']>;
  vault_not_ends_with?: InputMaybe<Scalars['String']>;
  vault_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vault_not_in?: InputMaybe<Array<Scalars['String']>>;
  vault_not_starts_with?: InputMaybe<Scalars['String']>;
  vault_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  vault_starts_with?: InputMaybe<Scalars['String']>;
  vault_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum ValidatorCuttingBoardWeight_OrderBy {
  Id = 'id',
  Owner = 'owner',
  OwnerId = 'owner__id',
  OwnerStartBlock = 'owner__startBlock',
  PercentageNumerator = 'percentageNumerator',
  Receiver = 'receiver',
  Vault = 'vault',
  VaultId = 'vault__id',
  VaultStakingTokenAmount = 'vault__stakingTokenAmount',
  VaultVaultAddress = 'vault__vaultAddress',
}

export type ValidatorCuttingBoard_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ValidatorCuttingBoard_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<ValidatorCuttingBoard_Filter>>>;
  startBlock?: InputMaybe<Scalars['BigInt']>;
  startBlock_gt?: InputMaybe<Scalars['BigInt']>;
  startBlock_gte?: InputMaybe<Scalars['BigInt']>;
  startBlock_in?: InputMaybe<Array<Scalars['BigInt']>>;
  startBlock_lt?: InputMaybe<Scalars['BigInt']>;
  startBlock_lte?: InputMaybe<Scalars['BigInt']>;
  startBlock_not?: InputMaybe<Scalars['BigInt']>;
  startBlock_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  validator?: InputMaybe<Scalars['String']>;
  validator_?: InputMaybe<Validator_Filter>;
  validator_contains?: InputMaybe<Scalars['String']>;
  validator_contains_nocase?: InputMaybe<Scalars['String']>;
  validator_ends_with?: InputMaybe<Scalars['String']>;
  validator_ends_with_nocase?: InputMaybe<Scalars['String']>;
  validator_gt?: InputMaybe<Scalars['String']>;
  validator_gte?: InputMaybe<Scalars['String']>;
  validator_in?: InputMaybe<Array<Scalars['String']>>;
  validator_lt?: InputMaybe<Scalars['String']>;
  validator_lte?: InputMaybe<Scalars['String']>;
  validator_not?: InputMaybe<Scalars['String']>;
  validator_not_contains?: InputMaybe<Scalars['String']>;
  validator_not_contains_nocase?: InputMaybe<Scalars['String']>;
  validator_not_ends_with?: InputMaybe<Scalars['String']>;
  validator_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  validator_not_in?: InputMaybe<Array<Scalars['String']>>;
  validator_not_starts_with?: InputMaybe<Scalars['String']>;
  validator_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  validator_starts_with?: InputMaybe<Scalars['String']>;
  validator_starts_with_nocase?: InputMaybe<Scalars['String']>;
  weights_?: InputMaybe<ValidatorCuttingBoardWeight_Filter>;
};

export enum ValidatorCuttingBoard_OrderBy {
  Id = 'id',
  StartBlock = 'startBlock',
  Validator = 'validator',
  ValidatorAmountQueued = 'validator__amountQueued',
  ValidatorAmountStaked = 'validator__amountStaked',
  ValidatorCoinbase = 'validator__coinbase',
  ValidatorCommission = 'validator__commission',
  ValidatorId = 'validator__id',
  Weights = 'weights',
}

export type Validator_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  allTimeValidatorIncentivesData_?: InputMaybe<AllTimeValidatorIncentivesData_Filter>;
  amountQueued?: InputMaybe<Scalars['BigDecimal']>;
  amountQueued_gt?: InputMaybe<Scalars['BigDecimal']>;
  amountQueued_gte?: InputMaybe<Scalars['BigDecimal']>;
  amountQueued_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  amountQueued_lt?: InputMaybe<Scalars['BigDecimal']>;
  amountQueued_lte?: InputMaybe<Scalars['BigDecimal']>;
  amountQueued_not?: InputMaybe<Scalars['BigDecimal']>;
  amountQueued_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  amountStaked?: InputMaybe<Scalars['BigDecimal']>;
  amountStaked_gt?: InputMaybe<Scalars['BigDecimal']>;
  amountStaked_gte?: InputMaybe<Scalars['BigDecimal']>;
  amountStaked_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  amountStaked_lt?: InputMaybe<Scalars['BigDecimal']>;
  amountStaked_lte?: InputMaybe<Scalars['BigDecimal']>;
  amountStaked_not?: InputMaybe<Scalars['BigDecimal']>;
  amountStaked_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  and?: InputMaybe<Array<InputMaybe<Validator_Filter>>>;
  coinbase?: InputMaybe<Scalars['Bytes']>;
  coinbase_contains?: InputMaybe<Scalars['Bytes']>;
  coinbase_gt?: InputMaybe<Scalars['Bytes']>;
  coinbase_gte?: InputMaybe<Scalars['Bytes']>;
  coinbase_in?: InputMaybe<Array<Scalars['Bytes']>>;
  coinbase_lt?: InputMaybe<Scalars['Bytes']>;
  coinbase_lte?: InputMaybe<Scalars['Bytes']>;
  coinbase_not?: InputMaybe<Scalars['Bytes']>;
  coinbase_not_contains?: InputMaybe<Scalars['Bytes']>;
  coinbase_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  commission?: InputMaybe<Scalars['BigDecimal']>;
  commission_gt?: InputMaybe<Scalars['BigDecimal']>;
  commission_gte?: InputMaybe<Scalars['BigDecimal']>;
  commission_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  commission_lt?: InputMaybe<Scalars['BigDecimal']>;
  commission_lte?: InputMaybe<Scalars['BigDecimal']>;
  commission_not?: InputMaybe<Scalars['BigDecimal']>;
  commission_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  dailyValidatorIncentivesData_?: InputMaybe<DailyValidatorIncentivesData_Filter>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<Validator_Filter>>>;
  validatorCuttingBoard_?: InputMaybe<ValidatorCuttingBoard_Filter>;
};

export enum Validator_OrderBy {
  AllTimeValidatorIncentivesData = 'allTimeValidatorIncentivesData',
  AllTimeValidatorIncentivesDataAllTimeBgtDirected = 'allTimeValidatorIncentivesData__allTimeBgtDirected',
  AllTimeValidatorIncentivesDataAllTimeBlocksProposed = 'allTimeValidatorIncentivesData__allTimeBlocksProposed',
  AllTimeValidatorIncentivesDataAllTimeUsdValueBgtDirected = 'allTimeValidatorIncentivesData__allTimeUsdValueBgtDirected',
  AllTimeValidatorIncentivesDataAllTimeUsdValueTokenRewards = 'allTimeValidatorIncentivesData__allTimeUsdValueTokenRewards',
  AllTimeValidatorIncentivesDataId = 'allTimeValidatorIncentivesData__id',
  AmountQueued = 'amountQueued',
  AmountStaked = 'amountStaked',
  Coinbase = 'coinbase',
  Commission = 'commission',
  DailyValidatorIncentivesData = 'dailyValidatorIncentivesData',
  Id = 'id',
  ValidatorCuttingBoard = 'validatorCuttingBoard',
  ValidatorCuttingBoardId = 'validatorCuttingBoard__id',
  ValidatorCuttingBoardStartBlock = 'validatorCuttingBoard__startBlock',
}

export type Vault = {
  __typename?: 'Vault';
  activeIncentives?: Maybe<Array<ActiveIncentive>>;
  allTimeVaultData?: Maybe<AllTimeVaultData>;
  dailyVaultData?: Maybe<Array<DailyVaultData>>;
  id: Scalars['ID'];
  stakingToken: TokenInformation;
  stakingTokenAmount: Scalars['BigDecimal'];
  vaultAddress: Scalars['Bytes'];
  vaultWhitelist?: Maybe<VaultWhiteList>;
};

export type VaultActiveIncentivesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ActiveIncentive_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ActiveIncentive_Filter>;
};

export type VaultDailyVaultDataArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DailyVaultData_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DailyVaultData_Filter>;
};

export type VaultWhiteList = {
  __typename?: 'VaultWhiteList';
  id: Scalars['ID'];
  vault: Vault;
  vaultAddress: Scalars['Bytes'];
  whiteListedTokens?: Maybe<Array<VaultWhiteListToken>>;
};

export type VaultWhiteListWhiteListedTokensArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<VaultWhiteListToken_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VaultWhiteListToken_Filter>;
};

export type VaultWhiteListToken = {
  __typename?: 'VaultWhiteListToken';
  id: Scalars['ID'];
  isWhiteListed: Scalars['Boolean'];
  vaultWhiteList: VaultWhiteList;
  whiteListedToken: TokenInformation;
};

export type VaultWhiteListToken_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<VaultWhiteListToken_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  isWhiteListed?: InputMaybe<Scalars['Boolean']>;
  isWhiteListed_in?: InputMaybe<Array<Scalars['Boolean']>>;
  isWhiteListed_not?: InputMaybe<Scalars['Boolean']>;
  isWhiteListed_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  or?: InputMaybe<Array<InputMaybe<VaultWhiteListToken_Filter>>>;
  vaultWhiteList?: InputMaybe<Scalars['String']>;
  vaultWhiteList_?: InputMaybe<VaultWhiteList_Filter>;
  vaultWhiteList_contains?: InputMaybe<Scalars['String']>;
  vaultWhiteList_contains_nocase?: InputMaybe<Scalars['String']>;
  vaultWhiteList_ends_with?: InputMaybe<Scalars['String']>;
  vaultWhiteList_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vaultWhiteList_gt?: InputMaybe<Scalars['String']>;
  vaultWhiteList_gte?: InputMaybe<Scalars['String']>;
  vaultWhiteList_in?: InputMaybe<Array<Scalars['String']>>;
  vaultWhiteList_lt?: InputMaybe<Scalars['String']>;
  vaultWhiteList_lte?: InputMaybe<Scalars['String']>;
  vaultWhiteList_not?: InputMaybe<Scalars['String']>;
  vaultWhiteList_not_contains?: InputMaybe<Scalars['String']>;
  vaultWhiteList_not_contains_nocase?: InputMaybe<Scalars['String']>;
  vaultWhiteList_not_ends_with?: InputMaybe<Scalars['String']>;
  vaultWhiteList_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vaultWhiteList_not_in?: InputMaybe<Array<Scalars['String']>>;
  vaultWhiteList_not_starts_with?: InputMaybe<Scalars['String']>;
  vaultWhiteList_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  vaultWhiteList_starts_with?: InputMaybe<Scalars['String']>;
  vaultWhiteList_starts_with_nocase?: InputMaybe<Scalars['String']>;
  whiteListedToken?: InputMaybe<Scalars['String']>;
  whiteListedToken_?: InputMaybe<TokenInformation_Filter>;
  whiteListedToken_contains?: InputMaybe<Scalars['String']>;
  whiteListedToken_contains_nocase?: InputMaybe<Scalars['String']>;
  whiteListedToken_ends_with?: InputMaybe<Scalars['String']>;
  whiteListedToken_ends_with_nocase?: InputMaybe<Scalars['String']>;
  whiteListedToken_gt?: InputMaybe<Scalars['String']>;
  whiteListedToken_gte?: InputMaybe<Scalars['String']>;
  whiteListedToken_in?: InputMaybe<Array<Scalars['String']>>;
  whiteListedToken_lt?: InputMaybe<Scalars['String']>;
  whiteListedToken_lte?: InputMaybe<Scalars['String']>;
  whiteListedToken_not?: InputMaybe<Scalars['String']>;
  whiteListedToken_not_contains?: InputMaybe<Scalars['String']>;
  whiteListedToken_not_contains_nocase?: InputMaybe<Scalars['String']>;
  whiteListedToken_not_ends_with?: InputMaybe<Scalars['String']>;
  whiteListedToken_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  whiteListedToken_not_in?: InputMaybe<Array<Scalars['String']>>;
  whiteListedToken_not_starts_with?: InputMaybe<Scalars['String']>;
  whiteListedToken_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  whiteListedToken_starts_with?: InputMaybe<Scalars['String']>;
  whiteListedToken_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum VaultWhiteListToken_OrderBy {
  Id = 'id',
  IsWhiteListed = 'isWhiteListed',
  VaultWhiteList = 'vaultWhiteList',
  VaultWhiteListId = 'vaultWhiteList__id',
  VaultWhiteListVaultAddress = 'vaultWhiteList__vaultAddress',
  WhiteListedToken = 'whiteListedToken',
  WhiteListedTokenAddress = 'whiteListedToken__address',
  WhiteListedTokenBeraValue = 'whiteListedToken__beraValue',
  WhiteListedTokenDecimals = 'whiteListedToken__decimals',
  WhiteListedTokenId = 'whiteListedToken__id',
  WhiteListedTokenName = 'whiteListedToken__name',
  WhiteListedTokenSymbol = 'whiteListedToken__symbol',
  WhiteListedTokenUsdValue = 'whiteListedToken__usdValue',
}

export type VaultWhiteList_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<VaultWhiteList_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<VaultWhiteList_Filter>>>;
  vault?: InputMaybe<Scalars['String']>;
  vaultAddress?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_contains?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_gt?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_gte?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_in?: InputMaybe<Array<Scalars['Bytes']>>;
  vaultAddress_lt?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_lte?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_not?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_not_contains?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  vault_?: InputMaybe<Vault_Filter>;
  vault_contains?: InputMaybe<Scalars['String']>;
  vault_contains_nocase?: InputMaybe<Scalars['String']>;
  vault_ends_with?: InputMaybe<Scalars['String']>;
  vault_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vault_gt?: InputMaybe<Scalars['String']>;
  vault_gte?: InputMaybe<Scalars['String']>;
  vault_in?: InputMaybe<Array<Scalars['String']>>;
  vault_lt?: InputMaybe<Scalars['String']>;
  vault_lte?: InputMaybe<Scalars['String']>;
  vault_not?: InputMaybe<Scalars['String']>;
  vault_not_contains?: InputMaybe<Scalars['String']>;
  vault_not_contains_nocase?: InputMaybe<Scalars['String']>;
  vault_not_ends_with?: InputMaybe<Scalars['String']>;
  vault_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vault_not_in?: InputMaybe<Array<Scalars['String']>>;
  vault_not_starts_with?: InputMaybe<Scalars['String']>;
  vault_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  vault_starts_with?: InputMaybe<Scalars['String']>;
  vault_starts_with_nocase?: InputMaybe<Scalars['String']>;
  whiteListedTokens_?: InputMaybe<VaultWhiteListToken_Filter>;
};

export enum VaultWhiteList_OrderBy {
  Id = 'id',
  Vault = 'vault',
  VaultAddress = 'vaultAddress',
  VaultId = 'vault__id',
  VaultStakingTokenAmount = 'vault__stakingTokenAmount',
  VaultVaultAddress = 'vault__vaultAddress',
  WhiteListedTokens = 'whiteListedTokens',
}

export type Vault_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  activeIncentives_?: InputMaybe<ActiveIncentive_Filter>;
  allTimeVaultData_?: InputMaybe<AllTimeVaultData_Filter>;
  and?: InputMaybe<Array<InputMaybe<Vault_Filter>>>;
  dailyVaultData_?: InputMaybe<DailyVaultData_Filter>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<Vault_Filter>>>;
  stakingToken?: InputMaybe<Scalars['String']>;
  stakingTokenAmount?: InputMaybe<Scalars['BigDecimal']>;
  stakingTokenAmount_gt?: InputMaybe<Scalars['BigDecimal']>;
  stakingTokenAmount_gte?: InputMaybe<Scalars['BigDecimal']>;
  stakingTokenAmount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  stakingTokenAmount_lt?: InputMaybe<Scalars['BigDecimal']>;
  stakingTokenAmount_lte?: InputMaybe<Scalars['BigDecimal']>;
  stakingTokenAmount_not?: InputMaybe<Scalars['BigDecimal']>;
  stakingTokenAmount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  stakingToken_?: InputMaybe<TokenInformation_Filter>;
  stakingToken_contains?: InputMaybe<Scalars['String']>;
  stakingToken_contains_nocase?: InputMaybe<Scalars['String']>;
  stakingToken_ends_with?: InputMaybe<Scalars['String']>;
  stakingToken_ends_with_nocase?: InputMaybe<Scalars['String']>;
  stakingToken_gt?: InputMaybe<Scalars['String']>;
  stakingToken_gte?: InputMaybe<Scalars['String']>;
  stakingToken_in?: InputMaybe<Array<Scalars['String']>>;
  stakingToken_lt?: InputMaybe<Scalars['String']>;
  stakingToken_lte?: InputMaybe<Scalars['String']>;
  stakingToken_not?: InputMaybe<Scalars['String']>;
  stakingToken_not_contains?: InputMaybe<Scalars['String']>;
  stakingToken_not_contains_nocase?: InputMaybe<Scalars['String']>;
  stakingToken_not_ends_with?: InputMaybe<Scalars['String']>;
  stakingToken_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  stakingToken_not_in?: InputMaybe<Array<Scalars['String']>>;
  stakingToken_not_starts_with?: InputMaybe<Scalars['String']>;
  stakingToken_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  stakingToken_starts_with?: InputMaybe<Scalars['String']>;
  stakingToken_starts_with_nocase?: InputMaybe<Scalars['String']>;
  vaultAddress?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_contains?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_gt?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_gte?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_in?: InputMaybe<Array<Scalars['Bytes']>>;
  vaultAddress_lt?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_lte?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_not?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_not_contains?: InputMaybe<Scalars['Bytes']>;
  vaultAddress_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  vaultWhitelist_?: InputMaybe<VaultWhiteList_Filter>;
};

export enum Vault_OrderBy {
  ActiveIncentives = 'activeIncentives',
  AllTimeVaultData = 'allTimeVaultData',
  AllTimeVaultDataAllTimeBgtReceived = 'allTimeVaultData__allTimeBgtReceived',
  AllTimeVaultDataAllTimeUsdValueBgtReceived = 'allTimeVaultData__allTimeUsdValueBgtReceived',
  AllTimeVaultDataAllTimeUsdValueTokensEmitted = 'allTimeVaultData__allTimeUsdValueTokensEmitted',
  AllTimeVaultDataId = 'allTimeVaultData__id',
  AllTimeVaultDataVaultAddress = 'allTimeVaultData__vaultAddress',
  DailyVaultData = 'dailyVaultData',
  Id = 'id',
  StakingToken = 'stakingToken',
  StakingTokenAmount = 'stakingTokenAmount',
  StakingTokenAddress = 'stakingToken__address',
  StakingTokenBeraValue = 'stakingToken__beraValue',
  StakingTokenDecimals = 'stakingToken__decimals',
  StakingTokenId = 'stakingToken__id',
  StakingTokenName = 'stakingToken__name',
  StakingTokenSymbol = 'stakingToken__symbol',
  StakingTokenUsdValue = 'stakingToken__usdValue',
  VaultAddress = 'vaultAddress',
  VaultWhitelist = 'vaultWhitelist',
  VaultWhitelistId = 'vaultWhitelist__id',
  VaultWhitelistVaultAddress = 'vaultWhitelist__vaultAddress',
}

export type _Block_ = {
  __typename?: '_Block_';
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']>;
  /** The block number */
  number: Scalars['Int'];
  /** The hash of the parent block */
  parentHash?: Maybe<Scalars['Bytes']>;
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars['Int']>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  __typename?: '_Meta_';
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   *
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars['String'];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean'];
};

export enum _SubgraphErrorPolicy_ {
  /** Data will be returned even if the subgraph has indexing errors */
  Allow = 'allow',
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  Deny = 'deny',
}

export type GetTokenInformationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetTokenInformationsQuery = {
  __typename?: 'Query';
  tokenInformations: Array<{
    __typename?: 'TokenInformation';
    address: string;
    usdValue: string;
  }>;
};

export const GetTokenInformationsDocument = gql`
  query GetTokenInformations {
    tokenInformations(where: { usdValue_gt: "0" }) {
      address
      usdValue
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType
) => action();

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper
) {
  return {
    GetTokenInformations(
      variables?: GetTokenInformationsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<GetTokenInformationsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetTokenInformationsQuery>(
            GetTokenInformationsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'GetTokenInformations',
        'query'
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
